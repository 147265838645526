import React, { useState, useEffect, useRef } from 'react'
import { Button, Field, Groupper, Header, Input, Message, Checkbox, Table, Icon, Toolbar } from "react-frontier";
import { UserExternal, CatalogEvent, Promoter, SetLoading } from '@arema/components/Classes';
import { Dropdown, Modal } from 'semantic-ui-react';
import { useNavigate, useParams } from "react-router-dom";
import { bindClose, bindFormChange } from '@arema/components/Util';
import { useTitle } from '@arema/components/Hooks';
import { ErrorHeader, NotFound } from '../../components';
import { useUser } from '../../AdminHooks';
import { UserExternalEvent } from '../../AdminClasses';
import Validator from "@arema/components/Validator";
import API from "./../../API";
import SearchEventModal from "../../components/SearchEventModal";
import SearchPromoterModal from "../../components/SearchPromoterModal";
import UserExternalAcccessModal from "../../components/UserExternalAcccessModal";
import Toast from "react-hot-toast";
import UserAccess from '../../UserAccess';
import moment from 'moment';

enum PASSWORD_MODE {
	AUTOGENERATED = 0,
	PROVIDED = 1,
}

enum Modals {
	SEARCH_EVENT = 100,
	SEARCH_PROMOTER = 200,
	DELETE_PROMOTER = 2001,

	USER_UPDATE_PASSWORD = 300,
	USER_EXTERNAL_ACCESS = 400,
	USER_EXTERNAL_REFRESH = 500,
}

interface UserForm {
	first_name: string,
	last_name: string,
	email: string,
	access: boolean,
	all_events: boolean,
}

var UserExternalCreate = ()=>{
	const navigate = useNavigate()
	var { external_id } = useParams();
	var { hasAccess } = useUser();
	var { setTitle } = useTitle();
	var IS_CREATE = external_id === 'create';
	var canChangePassword = !IS_CREATE && hasAccess(UserAccess.CATALOGS.EXTERNAL_CHANGE_PASSWORD);
	var [userEvents, setUserEvents] = useState<UserExternalEvent[]>([]);
	var [selectedEvent, setSelectedEvent] = useState<UserExternalEvent>(null);
	var [userPromoters, setUserPromoters] = useState<Promoter[]>([]);
	var [errorPrompts, setErrorPrompts] = useState<string[]>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [formData, setFormData] = useState<UserForm>(null)
	var [passwordForm, setPasswordForm] = useState<{ mode: PASSWORD_MODE, password: string }>({ mode: PASSWORD_MODE.AUTOGENERATED, password: '' });
	var [modalPrompts, setModalPrompts] = useState<string[]>(null);
	var [shownModal, setShownModal] = useState<Modals>(null);
	var [modalConfirm, setModalConfirm] = useState<boolean>(false);
	var [selectedPromoter, setSelectedPromoter] = useState<Promoter>(null);
	var userEventsRef = useRef(null);
	userEventsRef.current = userEvents;

	var onSaveEvents = (event: CatalogEvent): boolean => {
		var ix = userEvents.findIndex(a=>a.event_id===event.event_id);
		if(ix===-1){
			var nev = [...userEvents];
			var new_event : UserExternalEvent = {
				event_id: event.event_id,
				event_name: event.event_name,
				promoter_id: null,
				promoter_name: null,
				date_added: moment().unix(),
				access: []
			}
			nev.push(new_event);
			setUserEvents(nev);
			setShownModal(Modals.USER_EXTERNAL_ACCESS);
			setSelectedEvent(new_event);
		}else{
			setShownModal(Modals.USER_EXTERNAL_ACCESS);
			setSelectedEvent(userEvents[ix]);
		}
		return true;
	}

	var onSavePromoter = (promoter: Promoter, setLoading: SetLoading): boolean => {
		if(!userPromoters.some(p => p.promoter_id === promoter.promoter_id)){
			setLoading(true);
			API.saveUserExternalPromoter(parseInt(external_id), promoter.promoter_id).then((res) => {
				if (res.error) return Toast.error(res.message);
				var newPromoters = [...userPromoters];
				newPromoters.push(promoter);
				setUserPromoters(newPromoters);
				return Toast.success('Se guardo el promotor correctamente.');
			}).catch(() => {
				return Toast.error('Error inesperado (LCL-1)');
			}).finally(() => {
				setLoading(false);
			})
		} else {
			Toast.error('El promotor ya fue seleccionado previamente');
			return false;
		}
	}

	useEffect(()=>{
		var id = parseInt(external_id);
		if(!IS_CREATE && !Number.isNaN(id)){
			if(formData === null){
				loadData(id);
			}
		}else{
			setTitle('Crear Nuevo Empresario');
			setFormData({
				first_name: '',
				last_name: '',
				email: '',
				access: true,
				all_events: false,
			});
			setUserEvents([]);
		}
	}, []);

	var loadData = async (external_id: number)=>{
		API.getUserExternal(external_id).then(res=>{
			if(res.error) return setLoadError(res.message);
			setFormData({
				...res.data,
				access: !!res.data.access,
				all_events: !!res.data.all_events,
			});
			setTitle(`Empresario ${res.data.first_name} ${res.data.last_name}`)
			setUserEvents(res.data.events || []);
			setUserPromoters(res.data.promoters || []);
		}).catch(err=>{
			setLoadError('Hubo un error cargando el empresario (LCL-3)');
		});
	}

	var deleteEvents = (event_id: number)=>{
		return ()=>{
			var id = Toast.loading(`Borrando evento del empresario...`);
			API.saveUserExternalEventAccess(parseInt(external_id), event_id, []).then(res=>{
				if(res.error) return Toast.error(res.message);
				Toast.success('Evento eliminado');
				var newEvents = [...userEventsRef.current].filter(sev=>sev.event_id!==event_id);
				setUserEvents(newEvents);
			}).catch(err=>{
				Toast.error('Hubo un error inesperado eliminando el evento del empresario.');
			}).finally(()=>{
				Toast.dismiss(id);
			})
		}
	}

	var showEventAccess = (event: UserExternalEvent)=>{
		return ()=>{
			setShownModal(Modals.USER_EXTERNAL_ACCESS);
			setSelectedEvent(event);
		}
	}

	var onOpenDeletePromoter = (promoter: Promoter) => {
		return () => {
			setSelectedPromoter(promoter);
			setShownModal(Modals.DELETE_PROMOTER);
		}
	}

	var deletePromoter = (setLoading: SetLoading) => {
		setLoading(true);
		var promoter_id = selectedPromoter.promoter_id
		API.deleteUserExternalPromoter(parseInt(external_id), promoter_id).then((res) => {
			if (res.error) return Toast.error(res.message);
			var newPromoters = [...userPromoters].filter(sep => sep.promoter_id !== promoter_id);
			setUserPromoters(newPromoters);
			setShownModal(null);
			return Toast.success('Se eliminó el promotor correctamente.');
		}).catch(() => {
			return Toast.error('Error inesperado (LCL-1)');
		}).finally(() => {
			setLoading(false);
		});
	}

	var onEventAccessChange = (event: UserExternalEvent, access: number[])=>{
		var ix = userEvents.findIndex(a=>a.event_id===event.event_id);
		if(ix===-1){
			if(access.length==0) return;
			setUserEvents([...userEvents, {
				...event,
				access
			}])
		}else{
			var uev = [...userEvents];
			if(access.length==0){
				uev.splice(ix, 1);
			}else{
				uev[ix].access = access;
			}
			setUserEvents(uev);
		}
		setSelectedEvent(null);
	}

	var submit = async (setLoading: (b: boolean) => void)=>{
		var { valid, prompts } = Validator(formData, {
			first_name: [{ rule: 'length', params: [3, 32], label: 'Nombre' }],
			last_name: [{ rule: 'length', params: [3, 32], label: 'Apellido' }],
			email: [{ rule: "email", label: 'Correo electrónico' }],
		});
		setErrorPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		var saveUser: UserExternal = {
			external_id: Number(external_id),
			first_name: formData.first_name,
			last_name: formData.last_name,
			email: formData.email,
			all_events: formData.all_events,
			access: formData.access,
		}
		if(IS_CREATE){
			API.createUserExternal(saveUser, userEvents.map(a => a.event_id), userPromoters.map(a => a.promoter_id)).then(res => {
				if(res.error) return setErrorPrompts([res.message]);
				Toast.success('Se creó el usuario');
				navigate(`/c/partners/${res.data.external_id}`);
			}).catch(err => {
				setErrorPrompts(['Hubo un error inesperadoal crear el usuario (LCL-10)']);
			}).finally(()=>{
				setLoading(false);
			});
		}else{
			API.editUserExternal(saveUser, userEvents.map(a => a.event_id), userPromoters.map(a => a.promoter_id)).then(res => {
				if(res.error) return setErrorPrompts([res.message]);
				Toast.success('Se actualizo el usuario');
			}).catch(err => {
				setErrorPrompts(['Hubo un error inesperado actualizando el usuario (LCL-10)']);
			}).finally(()=>{
				setLoading(false);
			});
		}
	}

	var sendPasswordChange = (setLoading: SetLoading)=>{
		var { valid, prompts } = Validator(passwordForm, {
			mode: ['number'],
			password: passwordForm.mode === PASSWORD_MODE.AUTOGENERATED ? [] : [
				{ rule: 'password' }
			]
		});
		setModalPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.changeUserExternalPassword(parseInt(external_id), passwordForm.mode === PASSWORD_MODE.AUTOGENERATED, passwordForm.password).then(res => {
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Se ha cambiado la contraseña del usuario y se le ha enviado por correo.');
			setPasswordForm({ password: '', mode:PASSWORD_MODE.AUTOGENERATED });
			setShownModal(null);
		}).catch(err => {
			setModalPrompts(['Hubo un error inesperado cambiando la contraseña (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var changePassword = ()=>{
		if(!canChangePassword) return;
		setPasswordForm({
			mode: PASSWORD_MODE.AUTOGENERATED,
			password: ''
		});
		setShownModal(Modals.USER_UPDATE_PASSWORD);
		setModalPrompts([]);
	}

	var showRefreshModal = ()=>{
		setShownModal(Modals.USER_UPDATE_PASSWORD);
		setModalConfirm(false);
		setModalPrompts([]);
	}

	var sendReinitialize = (setLoading: SetLoading)=>{
		setLoading(true);
		API.refreshExternalSecret(parseInt(external_id)).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Se ha reiniciado.');
			setShownModal(null);
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado reinicializando el PDV. (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		});
	}

	if(!IS_CREATE && Number.isNaN(parseInt(external_id))){
		return <NotFound />
	}
	if(loadError){
		return <ErrorHeader error={loadError} />
	}
	if(!formData){
		return <Header loading text='Cargando Usuario' />
	}

	var onFormChange = bindFormChange(formData, setFormData);
	var onFormPassChange = bindFormChange(passwordForm, setPasswordForm);

	return <>
		<Groupper title={IS_CREATE ? 'Nuevo empresario' : 'Editar empresario'} width={600}>
			{!IS_CREATE &&
				<Toolbar fitted>
					{canChangePassword && (
						<Toolbar.Item text='Cambiar contraseña' icon='key' onClick={changePassword} />
					)}
					{hasAccess(UserAccess.CATALOGS.EXTERNAL_CHANGE_PASSWORD) && (
						<Toolbar.Item text='Reiniciar Sesion' icon='refresh' onClick={showRefreshModal} />
					)}
				</Toolbar>
			}
			<Field amount={2}>
				<Input label="Nombre" value={formData.first_name} onChange={onFormChange('first_name')} />
				<Input label="Apellido" value={formData.last_name} onChange={onFormChange('last_name')} />
			</Field>
			<Input label="Correo electrónico" value={formData.email} onChange={onFormChange('email')} />
			<Checkbox label={'Acceso'} onChange={onFormChange('access')} checked={formData.access} style={{ marginTop: 15 }} />
			<Checkbox label={'Este usuario puede ver cualquier evento'} onChange={onFormChange('all_events')} checked={formData.all_events} style={{ marginTop: 10 }} />

			{IS_CREATE && (
				<Message type='info' text='La contraseña del usuario se le enviará a el correo electrónico que se ingresó.' style={{ marginTop: 15 }} />
			)}

			{(!formData.all_events && !IS_CREATE) && <>
				<Table
					title='Eventos'
					className='top fitted last'
					button={(
						<Button text='Agregar' color='black' size='tiny' iconName='plus-circle' onClick={bindClose(setShownModal, Modals.SEARCH_EVENT)} />
					)}
					style={{ marginTop: 20, marginBottom: 0 }}
					headers={['ID', 'Nombre', 'Permisos', <Icon name='pen' />]}
					collapsingIndexes={[0, 2, 3]}
					centeredIndexes={[0, 3]}
					emptyText='El usuario no tiene eventos asignados'
					data={userEvents.map(a=>([
						a.event_id,
						a.event_name,
						`${a.access.length} permiso${a.access.length===1 ? '' : 's'}`,
						<Dropdown icon={null} trigger={(
							<Button icon color='black' iconName="pen" size="tiny" />
						)}>
							<Dropdown.Menu>
								<Dropdown.Item icon='lock' text='Permisos' onClick={showEventAccess(a)} />
								<Dropdown.Item icon='remove' text='Eliminar' onClick={deleteEvents(a.event_id)} />
							</Dropdown.Menu>
						</Dropdown>
					]))}
				/>
				<Table
					title='Promotores'
					className='top fitted last'
					button={(
						<Button text='Agregar' color='black' size='tiny' iconName='plus-circle' onClick={bindClose(setShownModal, Modals.SEARCH_PROMOTER)} />
					)}
					style={{ marginTop: 15, marginBottom: 0 }}
					headers={['ID', 'Promotor', <Icon name='remove' />]}
					collapsingIndexes={[0, 2]}
					centeredIndexes={[0, 2]}
					emptyText='El usuario no tiene promotores asignados'
					data={userPromoters.map(a=>([
						a.promoter_id,
						a.promoter_name,
						<Dropdown icon={null} trigger={(
							<Button icon color='black' iconName="pen" size="tiny" />
						)}>
							<Dropdown.Menu>
								<Dropdown.Item icon='remove' text='Eliminar' onClick={onOpenDeletePromoter(a)} />
							</Dropdown.Menu>
						</Dropdown>
					]))}
				/>
			</>}

			<Message type='error' list={errorPrompts} style={{ marginTop: 15 }} />
			<div className="actions">
				<Button text="Guardar" color="black" onClick={submit} />
			</div>
		</Groupper>

		{!IS_CREATE && <>
			<SearchEventModal
				open={shownModal === Modals.SEARCH_EVENT}
				onClose={bindClose(setShownModal)}
				title='Buscar Evento'
				onEventSelected={onSaveEvents}
			/>

			<SearchPromoterModal
				open={shownModal === Modals.SEARCH_PROMOTER}
				onClose={bindClose(setShownModal)}
				title='Buscar Promotor'
				onSelected={onSavePromoter}
			/>

			<UserExternalAcccessModal
				title={'Editar permisos'}
				open={selectedEvent && !IS_CREATE}
				onClose={bindClose(setSelectedEvent)}
				onAccessChange={onEventAccessChange}
				external_id={parseInt(external_id)}
				event={selectedEvent}
				access={selectedEvent?.access || []}
			/>

			<Modal open={shownModal === Modals.DELETE_PROMOTER} onClose={bindClose(setShownModal)} size="mini">
				<Modal.Header>Eliminar Promotor</Modal.Header>
				<Modal.Content>
					<Header size='small' text='¿Eliminar el promotor de este empresario?' />
					<ul style={{ paddingLeft: 15 }}>
						<li>Se eliminara el promotor de este empresario</li>
					</ul>
				</Modal.Content>
				<Modal.Actions>
					<Button text='Cancelar' basic onClick={bindClose(setShownModal)} />
					<Button text='Eliminar' color={'red'} onClick={deletePromoter} />
				</Modal.Actions>
			</Modal>
		</>}

		{!!canChangePassword && (
			<Modal open={shownModal === Modals.USER_UPDATE_PASSWORD} onClose={bindClose(setShownModal)} size="tiny">
				<Modal.Header>Cambiar contraseña</Modal.Header>
				<Modal.Content>
					<Header text="Cambiar contraseña de usuario" subtext="Se cambiará la contraseña del usuario y se le mandará un correo con la contraseña." />
					<Checkbox 
						label="Autogenerar contraseña" 
						style={{ marginTop: 20 }} 
						checked={passwordForm.mode == PASSWORD_MODE.AUTOGENERATED} 
						onChange={()=>{ setPasswordForm({ password: '', mode: passwordForm.mode === PASSWORD_MODE.AUTOGENERATED ? PASSWORD_MODE.PROVIDED : PASSWORD_MODE.AUTOGENERATED }) }} 
					/>
					{passwordForm && passwordForm.mode == PASSWORD_MODE.PROVIDED ? (
						<Input label="Contraseña" style={{ marginTop: 10 }} value={passwordForm.password}  onChange={onFormPassChange('password')} />
					) : null}

					<Message text="El sistema le pedirá a el usuario que eliga una nueva contraseña la siguiente vez que entre a el sistema." centered type="info" style={{ marginTop: 20 }} />
					<Message type="error" list={modalPrompts} style={{ marginTop: 10 }} />
				</Modal.Content>
				<Modal.Actions>
					<Button text="Cancelar" basic onClick={bindClose(setShownModal)} />
					<Button text="Guardar" color="black" onClick={sendPasswordChange} />
				</Modal.Actions>
			</Modal>
		)}

		{hasAccess(UserAccess.PDV.RESET_PDV) && (
			<Modal open={shownModal===Modals.USER_EXTERNAL_REFRESH} size='tiny' onClose={bindClose(setShownModal)}>
				<Modal.Header>Reiniciar Sesion</Modal.Header>
				<Modal.Content>
					<Header text='¿Reiniciar sesion del usuario externo?' style={{ marginBottom: 20 }} />
					<Message type='info' centered>
						Esto cerrará sesión en todos los dispositivos donde se inició sesión con este usuario. Se tendrá que acceder de nuevo con usuario y contraseña.
					</Message>
					<Checkbox checked={!!modalConfirm} onChange={bindClose(setModalConfirm, true)} label={'Deseo reiniciar la sesión'} style={{ marginTop: 20 }} />
					<Message list={modalPrompts} type='error' />
				</Modal.Content>
				<Modal.Actions>
					<Button text='Cerrar' basic onClick={bindClose(setShownModal)} />
					<Button text='Continuar' color='black' disabled={!modalConfirm} onClick={sendReinitialize} />
				</Modal.Actions>
			</Modal>
		)}
	</>
}

export default UserExternalCreate;
