import { PDV, SetLoading } from '@arema/components/Classes'
import React, { useEffect, useState } from 'react'
import { Button, Header, Table, Message, Label } from 'react-frontier'
import API from '../../API'
import { ErrorHeader } from '../../components'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Modal } from 'semantic-ui-react'
import { addCommas, bindClose } from '@arema/components/Util'
import Toast from 'react-hot-toast';
import { useUser } from '../../AdminHooks'

interface PdvCorte {
	corte_id: number,
	pdv_id: number,
	name_start: string,
	name_end: string,
	date_start: number,
	date_end: number,
	total: number,
	cancelled: boolean,
}

enum Modals {
	CONFIRM_OPEN_CORTE = 100,
}

interface PDVCorteListProps{
	pdv_id: number,
	pdv: PDV,
	onEditPdv: (pdv: PDV)=>void
}

var CorteRow = (props: { corte: PdvCorte })=>{
	var { corte } = props;
	return (
		<Table.Row
			compact
			selectable
			as={Link}
			to={`/pdv/${corte.pdv_id}/cortes/${corte.corte_id}`}
			collapsingIndexes={[0, 1, 3, 4]}
			data={[
				corte.corte_id,
				moment.unix(corte.date_end || corte.date_start).format('DD/MM/YY HH:mm:ss'),
				(corte.name_end || corte.name_start),
				<Label color={corte.cancelled ? 'red' : (corte.date_end ? 'green' : 'blue')} value={corte.cancelled ? 'Cancelado' : (corte.date_end ? 'Cerrado' : 'Activo')} style={{ minWidth: 100 }} />,
				addCommas(corte.total || 0)
			]}
		/>
	)
}

function PdvCorteList(props: PDVCorteListProps){
	var { user } = useUser();
	var [loadError, setLoadError] = useState<string>(null);
	var [cortes, setCortes] = useState<PdvCorte[]>(null);
	var [cortesOpen, setCortesOpen] = useState<PdvCorte[]>(null);
	var [showModal, setShowModal] = useState<Modals>(null);

	useEffect(()=>{
		if(props.pdv_id && !isNaN(props.pdv_id)){
			loadData()
		}
	}, [props.pdv_id])

	var loadData = ()=>{
		API.getPDVCortes(props.pdv_id).then(res=>{
			if(res.error) return setLoadError(res.message);
			setCortes(res.data.filter((a: PdvCorte)=>!!a.date_end));
			var open = res.data.filter((a: PdvCorte)=>{
				return !a.date_end && (!cortesOpen || cortesOpen.findIndex(b=>a.corte_id==a.corte_id)==-1);
			})
			setCortesOpen(cortesOpen ? [
				...cortesOpen,
				...open
			] : open);
		}).catch(err=>{
			setLoadError('Error inesperado cargando los cortes (LCL-1)');
		})
	}
	
	if(loadError){
		return <ErrorHeader error={loadError} />
	}
	if(!cortes){
		return <Header loading text='Cargando cortes' />
	}
	
	var openCorte = (setLoading: SetLoading)=>{
		setLoading(true);
		API.createPDVCorte(props.pdv_id).then((res)=>{
			if(res.error) return Toast.error(res.message);
			setShowModal(null);
			setCortes(cortes=>{
				var new_cortes = [
					{
						corte_id: res.data.corte_id,
						pdv_id: props.pdv_id,
						date_start: moment().unix(),
						name_start: `${user.first_name} ${user.last_name}`,
						name_end: null,
						date_end: null,
						cancelled: false,
						total: 0,
					},
					...cortes
				];
				return new_cortes;
			})
			return Toast.success('Se ha creado el corte de manera correcta')
		}).catch(()=>{
			return Toast.error('Error inesperado cerrando el corte (LCL-2)');
		}).finally(()=>{
			setLoading(false);
		})
	}

	if(!cortes) return <Header />

	return (
		<div style={{ maxWidth: '100%' }} > 
			{cortesOpen.length > 1 && (
				<Message 
					centered
					type="error" 
					header="Mas de 1 corte Abierto" 
					text={`Hay mas de 1 corte abierto, favor de cerrar ${cortesOpen.length-1} corte${cortesOpen.length==1 ? '' : 's'}.`} 
					style={{ maxWidth: 500, margin: 'auto', marginBottom: 15 }}
				/>
			)}
			<Table
				striped
				title='Cortes'
				headers={['ID', 'Fecha', 'Usuario', 'Estatus', 'Total']}
				style={{ maxWidth: '100%', minWidth: 700, width: 700, margin: 'auto' }}
				empty={cortes.length==0}
				emptyText='No hay historial de cortes en este PDV'
				button={(
					<Button
						size='tiny'
						iconName='cut'
						color='black'
						text='Abrir Corte'
						onClick={bindClose(setShowModal, Modals.CONFIRM_OPEN_CORTE)}
						disabled={cortesOpen.length>0}
					/>
				)}
			>
				{(!!cortesOpen && cortesOpen.length>0) && <>
					{cortesOpen.map(c=>(
						<CorteRow key={`CTRW-${c.corte_id}`} corte={c} />
					))}
					<Table.Divider />
				</>}
				{cortes.map(c=>(
					<CorteRow key={`CTRW-${c.corte_id}`} corte={c} />
				))}
			</Table>
			<Modal open={showModal === Modals.CONFIRM_OPEN_CORTE} onClose={bindClose(setShowModal)} size="mini">
				<Modal.Header>Abrir Corte</Modal.Header>
				<Modal.Content>
					<Header text={`¿Deseas abrir un nuevo corte en este PDV?`} />
					<Table
						details
						title='PDV'
						data={[
							['ID', props.pdv.pdv_id],
							['Nombre', props.pdv.pdv_name],
						]}
					/>
				</Modal.Content>
				<Modal.Actions>
					<Button onClick={bindClose(setShowModal)} text="Cancelar" basic />
					<Button text="Abrir Corte" color="black" onClick={openCorte} />
				</Modal.Actions>
			</Modal>
		</div>
	)
}

export default PdvCorteList;