import React, { useEffect, useState, useRef } from 'react';
import { Button, Field, Header, Message, Table, Icon } from 'react-frontier';
import { ErrorHeader, UserLog } from '../../components';
import { useParams, Link } from 'react-router-dom';
import { BankAccount, SetLoading } from '@arema/components/Classes';
import { addCommas, bindChange, bindClose, bindFormChange, dashify, getParticipant, uniqueArray } from '@arema/components/Util';
import { useTitle } from '@arema/components/Hooks';
import { Checkbox, Dropdown, Loader, Modal } from 'semantic-ui-react';
import { useUser } from '../../AdminHooks';
import API from '../../API';
import UserAccess from '../../UserAccess';
import classNames from 'classnames';
import Toast from 'react-hot-toast';
import moment from 'moment';

interface EventPayout {
	payout_id: number,
	event_id: number,
	event_name: string,
	payout_type: number,
	payout_type_name: number,
	payout_method: number,
	bank_account: number,
	completed: boolean,
	approved: boolean,
	rejected: boolean,
	cancelled: boolean,
	comments: string,
	reject_feedback: string,
	admin_creator: number,
	admin_creator_name?: string,
	admin_approver: number,
	admin_approver_name?: string,
	admin_completed: number,
	admin_completed_name?: string,
	admin_cancelled: number,
	admin_cancelled_name?: string,
	date_scheduled: number,
	date_created: number,
	date_cancelled: number,
	date_approved: number,
	date_completed: number,
	dates: {
		date_id: number,
		date: number,
		city: string,
		amount: number,
		cancelled: boolean,
	}[],
	account: BankAccount,
	evidence: {
		filename: string,
		date: number,
		link: string,
	}[],
}

enum ShownModal {
	APPROVE_COMPLETE = 1,
	REJECT = 2,
	EVIDENCE_DELETE = 3,
	DOWNLOAD_STP = 10,
}

var PayoutView = () => {
	var { id } = useParams();
	var { user, hasAccess } = useUser();
	var { setTitle } = useTitle();
	var [payout, setPayout] = useState<EventPayout>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [modalConfirm, setModalConfirm] = useState<boolean>(false);
	var [shownModal, setShownModal] = useState<ShownModal>(null);
	var [modalPrompts, setModalPrompts] = useState<string[]>(null);
	var [rejectComments, setRejectComments] = useState<string>('');
	var [loadingStp, setLoadingStp] = useState<boolean>(false);
	var [loadFile, setLoadFile] = useState<boolean>(false);
	var [selectedEvidence, setSelectedEvidence] = useState<EventPayout["evidence"][0]>(null);
	var [completeForm, setCompleteForm] = useState<{
		stp: boolean,
		completed: boolean
	}>(null);

	const hiddenFileInput = useRef(null)

	useEffect(() => {
		setTitle(`Liquidación ${id}`);
		if (!Number.isNaN(id) && payout === null) {
			API.getPayout(parseInt(id), true).then(res => {
				if (res.error) return setLoadError(res.message);
				setPayout(res.data);
			}).catch(err => {
				return setLoadError('Hubo un error inesperado cargando la liquidación. (LCL-1)');
			});
		}
	}, []);

	if (loadError) {
		return <ErrorHeader text='Error' error={loadError} />
	}
	if (!payout) {
		return <Header loading text='Cargando liquidación' />
	}

	var showModal = (modal: ShownModal) => {
		return () => {
			setCompleteForm({
				stp: false,
				completed: false,
			})
			setModalConfirm(false);
			setShownModal(modal);
		}
	}

	var approvePayout = (setLoading: SetLoading) => {
		if (!ACCESS.approve) {
			setShownModal(null);
			return Toast.error('No tienes acceso a aprobar liquidaciones.');
		}
		setLoading(true);
		API.approvePayouts([payout.payout_id]).then(res => {
			if (res.error) return Toast.error(res.message);
			Toast.success('Se ha aprobado la liquidación');
			setShownModal(null);
			var py = { ...payout };
			py.approved = true;
			py.admin_approver = user.admin_id;
			py.admin_approver_name = user.username;
			py.date_approved = moment().unix();
			if (!py.bank_account) {
				py.completed = true;
			}
			setPayout(py);
		}).catch(err => {
			Toast.error('Hubo un error inesperado aprobando la liquidación (LCL-1)');
		}).finally(() => {
			setLoading(false);
		})
	}

	var rejectPayout = (setLoading: SetLoading) => {
		if (!ACCESS.cancel) {
			setShownModal(null);
			return Toast.error('No tienes acceso a cancelar liquidaciones.');
		}
		if (!modalConfirm) return;
		if (!rejectComments || rejectComments.length < 5) {
			return setModalPrompts(['Favor de ingresar comentarios de la cancelación.']);
		}
		setModalPrompts(null);
		setLoading(true);
		API.rejectPayouts([payout.payout_id], rejectComments).then(res => {
			if (res.error) return setModalPrompts([res.message]);
			Toast.success('Se han rechazado las liquidaciones.');
			setShownModal(null);
			var py = { ...payout };
			py.cancelled = true;
			py.rejected = true;
			py.reject_feedback = rejectComments;
			py.admin_cancelled = user.admin_id;
			py.admin_cancelled_name = user.username;
			py.date_cancelled = moment().unix();
			setPayout(py);
		}).catch(err => {
			setModalPrompts(['Hubo un error inesperado rechazando la liquidación (LCL-1)']);
		}).finally(() => {
			setLoading(false);
		})
	}

	var completePayout = (force: boolean = false) => {
		return (setLoading: SetLoading) => {
			if (!ACCESS.complete) {
				setShownModal(null);
				return Toast.error('No tienes acceso a completar liquidaciones.');
			}
			if (!completeForm || (!completeForm.completed && !completeForm.stp)) {
				return setModalPrompts(['Favor de seleccionar descarga STP o ya depositado.']);
			}
			setModalPrompts(null);
			if (completeForm.stp && !force) {
				return showSTP(setLoading);
			}

			setLoading(true);
			API.completePayouts([payout.payout_id]).then(res => {
				if (res.error) return Toast.error(res.message);
				Toast.success('Se han completado las liquidaciones');
				setShownModal(null);

				var py = { ...payout };
				py.completed = true;
				py.rejected = false;
				py.cancelled = false;
				py.admin_completed = user.admin_id;
				py.admin_completed_name = user.username;
				py.date_completed = moment().unix();
				setPayout(py);
			}).catch(err => {
				Toast.error('Hubo un error inesperado completando las liquidaciónes (LCL-1)');
			}).finally(() => {
				setLoading(false);
			})
		}
	}

	var showSTP = (setLoading: SetLoading) => {
		if (!ACCESS.stp) {
			setShownModal(null);
			return Toast.error('No tienes acceso a descargar el archivo STP.');
		}
		setModalPrompts(null);
		setShownModal(ShownModal.DOWNLOAD_STP);
		setLoadingStp(true);
		API.downloadPayoutZip([payout.payout_id]).then(res => {
			if (res.error) return setModalPrompts([res.message]);
		}).catch(err => {
			setModalPrompts(['Hubo un error inesperado descargando el archivo STP. (LCL-1)']);
		}).finally(() => {
			setLoadingStp(false);
		});
	}

	var onEvidenceFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		if(!hasAccess(UserAccess.CONTABILIDAD.UPLOAD_PAYOUT_FILE)) return;
		const fileList = event.target.files;
		if (!fileList || !fileList[0]) return;
		if (fileList.length > 1) return Toast.error("Favor de solo seleccionar 1 archivo.");
		if (fileList[0].size > (2 * 1024 * 1024)) return Toast.error('El archivo no puede pesar mas de 2MB.');
		setLoadFile(true);
		API.uploadPayoutEvidence(payout.payout_id, fileList[0]).then(res => {
			if (res.error) return Toast.error(res.message);
			Toast.success('Se subío la evidencia correctamente');
			payout.evidence.push(res.data);
		}).catch(err => {
			return Toast.error('Hubo un error subiendo la evidencia de la liquidacion. (LCL-3)');
		}).finally(() => {
			setLoadFile(false);
		});
	}

	var onClickUpload = () => {
		if (hiddenFileInput) {
			hiddenFileInput.current.click();
		}
	}

	var showDeleteEvidence = (evidence: EventPayout["evidence"][0])=>{
		return ()=>{
			if(!hasAccess(UserAccess.CONTABILIDAD.UPLOAD_PAYOUT_FILE)) return;
			setSelectedEvidence(evidence);
			showModal(ShownModal.EVIDENCE_DELETE)();
		}
	}

	var deleteEvidence = (setLoading: SetLoading) => {
		if(!hasAccess(UserAccess.CONTABILIDAD.DELETE_PAYOUT_FILE)) return;
		setLoading(true);
		API.removePayoutEvidence(payout.payout_id, selectedEvidence.filename).then(res => {
			if (res.error) return setModalPrompts([res.message]);
			payout.evidence = payout.evidence.filter(e => e.filename !== selectedEvidence.filename)
			setPayout({...payout});
			setShownModal(null);
			Toast.success('Se eliminó la evidencia correctamente');
		}).catch(err=>{
			setModalPrompts(['Hubo un error eliminando la solicitud (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		});
	}

	var onCompleteFormChange = bindFormChange(completeForm, setCompleteForm);
	var dates = payout.dates.filter(a => !a.cancelled);
	var payout_total = dates.reduce((a, b) => a + b.amount, 0) || 0;
	var cities = uniqueArray(payout.dates.map(a => a.city));
	var unix_now = moment().unix();
	var bank_participant = payout.account && payout.account.bank_participant ? getParticipant(payout.account.bank_participant) : null;
	var ACCESS = {
		approve: hasAccess(UserAccess.CONTABILIDAD.APPROVE_PAYOUT),
		complete: hasAccess(UserAccess.CONTABILIDAD.SET_PAYOUT_COMPLETE),
		cancel: hasAccess(UserAccess.CONTABILIDAD.CANCEL_PAYOUT),
		stp: hasAccess(UserAccess.CONTABILIDAD.DOWNLOAD_STP_FILE),
		upload_evidence: hasAccess(UserAccess.CONTABILIDAD.UPLOAD_PAYOUT_FILE),
		remove_evidence: hasAccess(UserAccess.CONTABILIDAD.DELETE_PAYOUT_FILE),
	}

	return <div>
		{!payout.approved && !payout.cancelled && !payout.completed && (
			<Message style={{ margin: 'auto', marginBottom: 10, maxWidth: 600 }} type='warning' centered>
				<div className="header">Liquidación en aprobación</div>
				Para poder realizar la liquidación, un usuario administrativo tendrá que aprobar la liquidación.
				{payout.bank_account ? 'Una vez aprobada se podrá hacer la transferencia bancaria si es necesaria.' : 'Una vez aprobada, ya que no requiere transferencia bancaria se marcará como completada.'}
			</Message>
		)}
		<Table
			striped
			details
			title={`Liquidación ${id}`}
			style={{ maxWidth: 600, margin: 'auto' }}
			actions={!payout.cancelled ? (
				!payout.approved && !payout.cancelled && ACCESS.approve ? <>
					<Button text='Rechazar' color='red' onClick={showModal(ShownModal.REJECT)} />
					<Button text='Aprobar' color='green' onClick={showModal(ShownModal.APPROVE_COMPLETE)} />
				</> : !payout.completed ? <>
					{ACCESS.cancel && <Button text='Cancelar' basic onClick={showModal(ShownModal.REJECT)} />}
					{ACCESS.complete && <Button text='Completar' color='green' onClick={showModal(ShownModal.APPROVE_COMPLETE)} />}
				</> : null
			) : null}
		>
			<Table.Row data={['Evento', payout.event_name]} as={Link} compact to={`/events/${payout.event_id}`} />
			<Table.Row data={[`Ciudad${cities.length == 1 ? '' : 'es'}`, cities]} />
			<Table.Row data={['Tipo', payout.payout_type_name]} />
			<Table.Row data={['Cantidad', <b style={{ fontSize: 20 }}>{addCommas(payout_total)}</b>]} />
			<Table.Row data={['Fechas', dates.length]} />
			<Table.Row data={['Estatus', <>
				<div className={classNames('fr label', {
					red: payout.rejected || payout.cancelled,
					green: !payout.rejected && !payout.cancelled && payout.completed,
					yellow: !payout.rejected && !payout.cancelled && !payout.completed
				})} style={{ fontWeight: 'bold' }}>
					<i className={classNames('icon', {
						remove: payout.rejected || payout.cancelled,
						checkmark: !payout.rejected && !payout.cancelled && payout.completed,
						clock: !payout.rejected && !payout.cancelled && !payout.completed,
					})}></i>
					{
						payout.rejected ? 'Rechazada' :
						payout.cancelled ? 'Cancelada' :
						payout.completed ? 'Completada' :
						payout.approved ? 'Aprobada' : 'En aprobación'
					}
				</div>
			</>]} />
			{!!payout.date_scheduled && (
				<Table.Row data={['Agendado', <div className={classNames({
					red: (!payout.cancelled && !payout.completed && payout.date_scheduled < unix_now),
				})}>
					{(!payout.cancelled && !payout.completed && payout.date_scheduled < unix_now) && (
						<i className="exclamation circle icon"></i>
					)}
					<i className="calendar icon"></i>
					{moment.unix(payout.date_scheduled).format('DD/MMM/YY')}
				</div>]} />
			)}
			{payout.comments && payout.comments.length > 0 ? (
				<Table.Row data={['Comentarios', payout.comments]} />
			) : null}
		</Table>
		{!!payout.rejected && (
			<Message centered type='error' style={{ maxWidth: 600, margin: 'auto', marginTop: 15 }}>
				<div className="header">Liquidación rechazada</div>
				El equipo administrativo ha rechazado la liquidación, se ha proporcionado el siguiente feedback:
				<Message style={{ marginTop: 15, backgroundColor: '#ffefef' }} type='error'>
					{payout.reject_feedback}
				</Message>
			</Message>
		)}
		{!!payout.completed && !payout.cancelled && (
			<Table
				striped
				title='Evidencias'
				style={{ margin: 'auto', marginTop: 15, maxWidth: 600 }}
				centeredIndexes={[2]}
				collapsingIndexes={[1, 2]}
				headers={['Archivo', 'Fecha', <Icon name="ellipsis-h" />]}
				actions={ACCESS.upload_evidence ?
					<div style={{ textAlign: 'center' }} >
						<Button
							loading={loadFile}
							iconName='upload'
							text='Agregar evidencia'
							onClick={onClickUpload}
						/>
						<input
							ref={hiddenFileInput}
							onChange={onEvidenceFile}
							style={{ display: 'none' }}
							type="file"
							accept="application/pdf"
						/>
					</div> : null
				}
			>
				{(payout.evidence && payout.evidence.length > 0) ? payout.evidence.map(a => (
					<Table.Row key={`evi-${a.filename}`} collapsingIndexes={[1, 2]} centeredIndexes={[2]} data={[
						a.filename,
						moment(a.date).format('DD/MMM/YY HH:mm'),
						<Dropdown
							icon={null}
							trigger={(
								<Button icon iconName="ellipsis-h" size="tiny" />
							)}
						>
							<Dropdown.Menu>
								<Dropdown.Item as={Link} to={a.link} target='_blank'>Descargar</Dropdown.Item>
								{ACCESS.remove_evidence && (
									<Dropdown.Item onClick={showDeleteEvidence(a)}>Eliminar</Dropdown.Item>
								)}
							</Dropdown.Menu>
						</Dropdown>
					]} />
				)) : (
					<Table.Cell empty row colSpan={5}>
						No hay evidencias en esta liquidación
					</Table.Cell>
				)}
			</Table>
		)}

		{!!payout.bank_account && (
			<Table
				striped
				details
				title='Cuenta bancaria'
				style={{ margin: 'auto', maxWidth: 600, marginTop: 15 }}
			>
				<Table.Row data={['ID', payout.account.account_id]} />
				<Table.Row data={['Propietario', payout.account.name_account]} />
				<Table.Row data={['Correo', payout.account.email_account]} />
				{!!payout.account.rfc && (
					<Table.Row data={['RFC', payout.account.rfc]} />
				)}
				{!!payout.account.curp && (
					<Table.Row data={['CURP', payout.account.curp]} />
				)}
				{!!payout.account.postal_code && (
					<Table.Row data={['Código postal', payout.account.postal_code]} />
				)}
				<Table.Divider />
				<Table.Row data={['Tipo cuenta', <>
					<i className={`${payout.account.clabe ? 'landmark' : 'credit card'} icon`}></i>
					{payout.account.clabe ? 'Cuenta bancaria' : 'Tarjeta bancaria'}
				</>]} />
				<Table.Row data={['Banco', <>
					{bank_participant ? bank_participant.bank_name : payout.account.bank}
					{bank_participant && (
						<div className="meta">P: {bank_participant.participant}</div>
					)}
				</>]} />
				<Table.Row data={[
					payout.account.clabe ? 'Cuenta bancaria' : 'Tarjeta bancaria',
					payout.account.clabe ? dashify(payout.account.clabe, 6, ' ') : dashify(payout.account.card_number, 4, ' '),
				]} />
			</Table>
		)}
		{!!payout.dates && payout.dates.length > 0 && (
			<Table
				striped
				title='Calendarios'
				headers={['ID', 'Fecha', 'Ciudad', 'Estatus', 'Cantidad']}
				style={{ margin: 'auto', marginTop: 15, maxWidth: 600 }}
			>
				{payout.dates.sort((a, b) => ((a.cancelled ? 1 : 0) - (b.cancelled ? 1 : 0))).map(a => (
					<Table.Row key={`dt-${a.date_id}`} collapsingIndexes={[0, 2, 3, 4]} data={[
						a.date_id,
						moment.unix(a.date).format('DD/MMM/YY HH:mm'),
						a.city,
						<div className={classNames('fr label', {
							green: !payout.cancelled && payout.completed,
							yellow: !payout.cancelled && !payout.completed && !a.cancelled,
							red: payout.cancelled || a.cancelled
						})}>
							{
								(payout.cancelled || a.cancelled) ? 'Cancelado' :
								payout.completed ? 'Completado' : 'Pendiente'
							}
						</div>,
						addCommas(a.amount)
					]} />
				))}
			</Table>
		)}

		<UserLog user={{...payout, admin_id: payout.admin_creator}} style={{ margin: 'auto', maxWidth: 600, marginTop: 15 }} />

		<Modal size='tiny' open={shownModal === ShownModal.APPROVE_COMPLETE} onClose={bindClose(setShownModal)}>
			<Modal.Header>{payout.approved ? 'Completar' : 'Aprobar'} liquidación</Modal.Header>
			<Modal.Content>
				<Header text={`¿${payout.approved ? 'Completar' : 'Aprobar'} liquidación?`} subtext={`Marcará la liquidación como ${payout.approved ? 'completada' : 'aprobada'}`} style={{ paddingLeft: 0, paddingRight: 0, fontSize: 26 }} />
				<div style={{ textAlign: 'center' }}>
					{(payout.bank_account) ? 'Se agregará a la fila de liquidaciones pendientes a liquidar.' : 'Ya que la liquidación no es por transferencia se marcará la liquidación como completada.'}
				</div>
				<Table details titleSize='small'
					title='Información'
					style={{ marginTop: 15 }}
					data={[
						['ID', payout.payout_id],
						['Evento', payout.event_name],
						['Cantidad', addCommas(payout_total)],
						...(payout.date_scheduled ? [
							['Agendada', moment.unix(payout.date_scheduled).format('DD/MMM/YY')]
						] : []),
						['Creador', payout.admin_creator_name],
					]}
				/>
				{!!payout.approved && !!completeForm && <>
					<Checkbox toggle checked={completeForm.completed && !completeForm.stp} label={'Esta liquidación ya se depositó'} onChange={onCompleteFormChange('completed', true)} disabled={completeForm.stp} style={{ marginTop: 15, display: 'block' }} />
					{ACCESS.stp && (
						<Checkbox toggle checked={completeForm.stp && !completeForm.completed} label={'Descargar archivo STP'} onChange={onCompleteFormChange('stp', true)} disabled={completeForm.completed} style={{ marginTop: 5, display: 'block' }} />
					)}
				</>}
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cancelar' basic onClick={bindClose(setShownModal)} />
				<Button text={payout.approved ? 'Completar' : 'Aprobar'} color='black' onClick={payout.approved ? completePayout(false) : approvePayout} />
			</Modal.Actions>
		</Modal>
		<Modal size='mini' open={shownModal === ShownModal.REJECT} onClose={bindClose(setShownModal)}>
			<Modal.Header>{payout.approved ? 'Cancelar' : 'Rechazar'} liquidación</Modal.Header>
			<Modal.Content>
				<Header text={`¿${payout.approved ? 'Cancelar' : 'Rechazar'} liquidación?`} subtext={`Marcará la liquidación como cancelada`} style={{ paddingLeft: 0, paddingRight: 0, fontSize: 26 }} />
				<div style={{ textAlign: 'center' }}>
					{payout.admin_creator === user.admin_id ? <>
						Se rechazará la liquidación, ya que tu fuiste el que creó la liquidación <b>no se enviará ningún correo.</b>
					</> : (
						'Se rechazará la liquidación y se le enviará un correo con los comentarios ingresados a el creador de la liquidación.'
					)}
				</div>
				<Field label='Comentarios' style={{ marginTop: 15 }}>
					<textarea cols={30} rows={5} placeholder='Comentarios de la cancelación' onChange={bindChange(setRejectComments)} value={rejectComments}></textarea>
				</Field>
				<Checkbox toggle checked={modalConfirm} onChange={bindChange(setModalConfirm, true)} label="Esta liquidación no se ha realizado y se desea cancelar" style={{ marginTop: 15 }} />
				<Message list={modalPrompts} type='error' />
			</Modal.Content>
			<Modal.Actions>
				<Button text='Regresar' basic onClick={bindClose(setShownModal)} />
				<Button text='Cancelar' color='red' onClick={rejectPayout} disabled={!modalConfirm} />
			</Modal.Actions>
		</Modal>
		<Modal size='mini' open={shownModal === ShownModal.DOWNLOAD_STP}>
			<Modal.Header>Descarga STP</Modal.Header>
			{shownModal === ShownModal.DOWNLOAD_STP && <Modal.Content>
				<div style={{ textAlign: 'center', fontSize: 16 }}>Se ha hecho la descarga de el archivo para subir a STP.</div>
				<Message type='warning' centered style={{ marginTop: 15 }}>
					<div className="header">Confirmar descarga</div>
					En cuanto se descargue el archivo y se verifique que no se tengan errores, se necesitará <b>confirmar la descarga</b> para marcar las liquidaciones como liquidadas y evitar cualquier tipo de doble deposito.
					<br />
					{loadingStp ? (
						<Loader active inline style={{ marginTop: 15 }} />
					) : !modalPrompts || modalPrompts.length <= 0 ? (
						<Button color='black' onClick={completePayout(true)} disabled={modalPrompts && modalPrompts.length > 0} text='Confirmar descarga' style={{ marginTop: 10 }} />
					) : (
						<Button basic text='Reintentar descarga' style={{ marginTop: 15 }} color='black' onClick={showSTP} />
					)}
				</Message>
				<Message type='error' list={modalPrompts} />
				{(!modalPrompts || modalPrompts.length <= 0) && (
					<Button basic text='Reintentar descarga' disabled={loadingStp} size='tiny' style={{ display: 'block', width: 150, margin: 'auto', marginTop: 15 }} onClick={showSTP} />
				)}
			</Modal.Content>}
		</Modal>
		<Modal open={shownModal===ShownModal.EVIDENCE_DELETE} onClose={bindClose(setShownModal)} size='tiny'>
			<Modal.Header>Eliminar evidencia</Modal.Header>
			{!!selectedEvidence && <Modal.Content>
				<Header text='¿Eliminar evidencia?' subtext={'Se eliminará por completo la evidencia seleccionada de esta liquidación'} />
				<Table
					details
					title='Evidencia'
					titleSize='small'
					data={[
						['Liquidación', payout.payout_id],
						['Evidencia', selectedEvidence.filename],
						['Fecha creación', selectedEvidence.date],
						['Enlace', (
							<Link to={selectedEvidence.link} target='_blank' style={{ color: '#4183c4', padding: 0 }}>
								<Icon name='external-link' /> Ver evidencia
							</Link>
						)]
					]} />
			</Modal.Content>}
			<Modal.Actions>
				<Button text='Cerrar' basic onClick={bindClose(setShownModal)} />
				<Button text='Eliminar' color='red' onClick={deleteEvidence} />
			</Modal.Actions>
		</Modal>
	</div>
}

export default PayoutView;