import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Link, NavigateOptions, useLocation, useNavigate } from 'react-router-dom';
import { Icons, bindToggle } from '@arema/components/Util';
import { CDN_ROOT } from './AdminConfig';
import { Dropdown, Icon, Modal } from 'semantic-ui-react';
import { Input } from 'react-frontier';
import { Toaster } from 'react-hot-toast';
import { TitleProvider } from '@arema/components/Hooks'
import { PrinterProvider, useUser } from './AdminHooks';
import API from './API';

import './style/header.scss';
import UserAccess from './UserAccess';
import LoginResponse from './screens/LoginResponse';

interface MenuItem{
	text: string,
	access?: number[],
	href?: string,
	items?: MenuItem[],
	special?: boolean,
};

const DEFAULT_MENU : MenuItem[] = [
	{ 
		text: 'Eventos', items: [
			{ text: 'Buscador', 		href: '/events',			access: [UserAccess.EVENTS.VIEW_EVENTS], },
			{ text: 'Padres', 		href: '/parent',			access: [UserAccess.EVENTS.VIEW_EVENT_GROUP], },
			{ text: 'Promotores',	href: '/promoters',		access: [UserAccess.CATALOGS.PROMOTER_VIEW], },
			{ text: 'Recintos', 		href: '/venues',			access: [UserAccess.VENUES.VIEW_VENUES], },
		]
	},
	{
		text: 'Ordenes', items: [
			{ text: 'Buscar', 		href: '/orders',			access: [UserAccess.ORDERS.VIEW_ORDERS], },
			{ text: 'Reembolsos', 	href: '/refunds',			access: [UserAccess.ORDERS.VIEW_REFUND], },
		]
	},
	// { text: 'Stats',	 		 		href: '/stats',			access: [UserAccess.ADMIN.STATISTICS], },
	{ text: 'Catálogos', 			special: true },
	{
		text: 'Contabilidad', items: [
			{ text: 'Conciliar', 			href: '/conciliacion',						access: [UserAccess.CONTABILIDAD.ADD_DEPOSIT], },
			{ text: 'Taquillas', 			href: '/pdv',									access: [UserAccess.CONTABILIDAD.CREATE_PDV_DEPOSIT], },
			{ text: 'Liquidaciones', 		href: '/payouts',								access: [UserAccess.CONTABILIDAD.VIEW_PAYOUTS], },
			{ text: 'Tabla del Día', 		href: '/conciliacion/tabla',				access: [UserAccess.CONTABILIDAD.VIEW_DAY_TABLE], },
			{ text: 'Papeleta', 				href: '/conciliacion/papeleta',			access: [UserAccess.CONTABILIDAD.VIEW_PAPELETA], },
			{ text: 'Facturas', 				href: '/reportes/facturacion',			access: [UserAccess.REPORTS.INVOICE], },
			{ text: 'Zonas',					href: '/zones',								access: [UserAccess.CATALOGS.ZONES_VIEW], },
		]
	},
	{
		text: 'Herramientas', items: [
			{ text: 'Callcenter', 			href: '/tools/callcenter',				access: [UserAccess.SELLERS.ACCESS_CALLCENTER], },
			{ text: 'Config', 				href: '/tools/config',					access: [UserAccess.ADMIN.DEVELOPER], },
			{ text: 'Reenviador', 			href: '/tools/resender',				access: [UserAccess.ORDERS.MASSIVE_RESEND_EMAILS], },
		]
	},
	{ text: 'Reportes',				href: '/reportes',		access: [UserAccess.REPORTS.ACCESS] },
]

interface CatalogItem{
	name: string,
	href: string,
	icon: Icons | string,
	access: number,
}

var CATALOGS : CatalogItem[] = [
	{ name: 'Usuarios', 					href: '/admins', 				icon: 'user cog',				access: UserAccess.USERS.VIEW_USERS },
	{ name: 'Puntos de Venta', 		href: '/pdv', 					icon: 'cash register',		access: UserAccess.PDV.VIEW_PDV},
	{ name: 'Checadores', 				href: '/scanners', 			icon: 'qrcode',				access: UserAccess.CATALOGS.SCANNER_VIEW },
	{ name: 'Empresarios', 				href: '/c/partners', 		icon: 'user tie',				access: UserAccess.CATALOGS.EXTERNAL_VIEW },
	{ name: 'Formas Envio', 			href: '/c/delivery', 		icon: 'box',					access: UserAccess.CATALOGS.DELIVERY_METHODS_VIEW },
	{ name: 'Cupones', 					href: '/c/coupons', 			icon: 'tag',					access: UserAccess.CATALOGS.COUPONS_VIEW },
	{ name: 'Recintos', 					href: '/venues', 				icon: 'home',					access: UserAccess.VENUES.VIEW_VENUES },
	{ name: 'Eventos', 					href: '/events', 				icon: 'theater masks',		access: UserAccess.EVENTS.VIEW_EVENTS },
	{ name: 'Eventos Padre', 			href: '/parents',				icon: 'folder open',			access: UserAccess.EVENTS.VIEW_EVENT_GROUP },
	{ name: 'Liquidaciones', 			href: '/payouts',				icon: 'money check alt',	access: UserAccess.CONTABILIDAD.VIEW_PAYOUTS },
	{ name: 'Templates', 				href: '/templates',			icon: 'ticket alt',			access: UserAccess.TOOLS.TEMPLATES_VIEW },
	{ name: 'Zonas',						href: '/zones',				icon: 'object group',		access: UserAccess.CATALOGS.ZONES_VIEW },
	// { name: 'Vendedores', 				href: '/c/sellers', 			icon: 'user tag' },
	// { name: 'Carrusel', 					href: '/c/carousel', 		icon: 'image' },
];

interface ComponentProps extends PropsWithChildren{
	outlet: JSX.Element
}

interface SidebarMenuProps{
	text: string,
	items: MenuItem['items']
}

var SidebarMenu = (props: SidebarMenuProps)=>{
	var [shown, setShown] = useState<boolean>(false)

	return <div className="item dropdown">
		<div className="content" onClick={bindToggle(shown, setShown)}>
			<div className="text">{props.text}</div>
			<i className="dropdown icon"></i>
		</div>
		{shown ? (
			<div className="menu">
				{props.items.map(a=>(
					<Link className={`item`} to={a.href} key={`hdrmenu-item-${a.href}`} replace>{a.text}</Link>
				))}
			</div>
		) : null}
	</div>
}

var Navigation = (props: ComponentProps)=>{
	var { user, hasAccess, access, refresh } = useUser();

	var [searchModal, setSearchModal] = useState<boolean>(false);
	var [searchText, setSearchText] = useState<string>('');
	var [sidebar, setSidebar] = useState<boolean>(false);

	var searchInput = useRef<HTMLInputElement>();
	var location = useLocation();

	useEffect(()=>{
		// if(!permissions){
		// 	API.getLogin().then(res=>{
		// 		if(res.error){
		// 			return setPermissionError(res.message);
		// 		}
		// 		// setUser(res.data.user)
		// 		setPermissions(res.data.access);
		// 		setPermissionError(null);
		// 	}).catch(err=>{
		// 		setPermissionError('Hubo un error inesperado cargando los permisos (LCL-2)');
		// 	});
		// }
		// document.addEventListener('keyup', handleKey);
		// return ()=>{
		// 	document.removeEventListener('keyup', handleKey)
		// }
		setSearchModal(false);
	}, [location.pathname]);
	
	var logout = ()=>{
		API.logout();
	}

	var is_auth = !user && window.location.pathname==='/auth';
	if(is_auth){
		return <LoginResponse />
	}

	if(!user){
		return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', backgroundColor: '#f0f0f0' }}>
			<img src={`${CDN_ROOT}/assets/logo/LogoG_Small.png`} alt="AREMA" style={{ borderRadius: 4 }} />
		</div>
	}
	var getCatalogs = ()=>{
		var catalogs : CatalogItem[] = CATALOGS;
		if(searchText){
			catalogs = CATALOGS.filter(function(a){
				return a.name.toLowerCase().includes(searchText.toLowerCase());
			});
		}
		return catalogs;
	}
	
	var menu = [];
	for(var i of DEFAULT_MENU){
		if(i.items){
			var items = [];
			for(var m of i.items){
				if(hasAccess(m.access)) items.push(m);
			}
			if(items.length>0){
				if(items.length==1) menu.push(items[0])
				else menu.push({ text: i.text, items })
			}
		}else if(hasAccess(i.access)){
			menu.push(i);
		}
	}

	var showDebug = ()=>{
		console.log("SHOW DEBUG!");
	}

	var showSearch = ()=>{
		setSearchModal(true);
		setSearchText('');
		// setSearchSelect(0);
	}
	
	// var handleKey = (ev: KeyboardEvent)=>{
	// 	if(!searchModal) return;
	// 	var catalogs = getCatalogs();
	// 	if(ev.key==='Enter'){
	// 		if(searchSelect>=0 && searchSelect<=(catalogs.length-1)){
	// 			navigate(catalogs[searchSelect].href)
	// 		}
	// 	}else if(ev.key==='ArrowDown'){
	// 		setSearchSelect(Math.min(catalogs.length-1, searchSelect+1));
	// 	}else if(ev.key==='ArrowUp'){
	// 		setSearchSelect(Math.max(0, searchSelect-1));
	// 	}
	// }

	return <TitleProvider suffix='AREMA Admin'>
		<PrinterProvider>
			<div style={{ backgroundColor: '#f7f7f7', minHeight: '100vh', position: 'relative' }}>
				<div className="ar menu">
					<div className="left">
						<div className="hamburger" onClick={bindToggle(sidebar, setSidebar)}>
							<i className="sidebar icon"></i>
						</div>
						<Link className="icon" to={'/'} replace>
							<img src={`${CDN_ROOT}/assets/logo/LogoW_Small.png`} alt="Logo" />
						</Link>
						{menu.map(a=>{
							if(a.items && a.items.length!=1){
								if(a.items.length==0) return null;
								return <Dropdown className='item' text={a.text} key={`hdrmenu-drop-${a.text}`}>
									<Dropdown.Menu>
										{a.items.map(i=>(
											<Dropdown.Item text={i.text} as={Link} to={i.href} key={`hdrmenu-item-${i.href}`} />
										))}
									</Dropdown.Menu>
								</Dropdown>
							}else{
								var item = a.items && a.items.length==1 ? a.items[0] : a;
								return item.special ? (
									<div className={`item`} onClick={showSearch} key={`hdrmenu-item-${item.href}`}>{item.text}</div>
								) : (
									<Link className={`item`} to={item.href} key={`hdrmenu-item-${item.href}`} replace>{item.text}</Link>
								)
							}
						})}
						<div className="icon item" onClick={showDebug}>
							<Icon name="bug" style={{ color: 'blue', fontSize: 20 }} />
						</div>
					</div>
					<div className="right">
						<div className="icon item" onClick={showSearch}>
							<i className="search icon" style={{ fontSize: 20 }}></i>
						</div>
						<Dropdown className='item' text={user.username.toUpperCase()}>
							<Dropdown.Menu>
								{/* <Dropdown.Item text="Mi usuario" /> */}
								<Dropdown.Item text="Contraseña" as={Link} to={'/profile/password'} />
								<Dropdown.Item text="Logout" onClick={logout} />
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</div>
				{sidebar ? (
					<div className="ar menu-sidebar">
						{menu.map((a, i)=>{
							if(a.items && a.items.length!=1){
								if(a.items.length==0) return null;
								return <SidebarMenu text={a.text} items={a.items} key={`shdrmenu-drop-item-${i}`} />
							}else{
								var item = a.items && a.items.length==1 ? a.items[0] : a;
								return item.special ? (
									<div className='item' onClick={showSearch} key={`shdrmenu-item-${item.href}`}>{item.text}</div>
								) : (
									<Link className='item' to={item.href} key={`shdrmenu-item-${item.href}`} replace={true}>{item.text}</Link>
								)
							}
						})}
					</div>
				) : null}
				<div style={{ paddingTop: 50, paddingBottom: 50, minHeight: '100vh' }}>
					{props.outlet}
					{/* <RouterProvider router={RouterNav} /> */}
				</div>
				<Modal open={searchModal} onClose={()=>setSearchModal(false)} size="tiny" className='search catalog'>
					<Modal.Header>
						<Input autoFocus ref={searchInput} value={searchText} onChange={setSearchText} icon="search" placeholder='Buscar en AREMA...' style={{ marginBottom: 0 }} />
					</Modal.Header>
					<Modal.Content scrolling>
						<div className="fr selectable striped divided items" style={{ marginTop: 0 }}>
							{getCatalogs().filter(c=> hasAccess(c.access)).map((a, i)=>(
								<Link to={a.href} className="item" key={`clg-${a.href}-${i}`} replace={true}>
									<i className={`${a.icon} icon`}></i>	
									{a.name}
								</Link>
							))}
						</div>
					</Modal.Content>
				</Modal>
				<Toaster position="bottom-right" toastOptions={{
					duration: 5000,
					error: {
						iconTheme: {
							primary: 'white',
							secondary: '#ff4b4b'
						},
						style: {
							color: 'white',
							background: '#ff4b4b',
						}
					},
					success: {
						iconTheme: {
							primary: 'white',
							secondary: 'rgb(89, 179, 67)'
						},
						style: {
							color: 'white',
							background: 'rgb(89, 179, 67)'
						}
					}
				}} />
			</div>
		</PrinterProvider>
	</TitleProvider>
}

export default Navigation;