import React, { useEffect, useRef, useState } from 'react';
import { Button, Header, Input, Pagination, Table } from 'react-frontier';
import { SetLoading, Venue, VenueSection } from '@arema/components/Classes';
import { Modal } from 'semantic-ui-react';
import API from '../API';

// default search and select a venue
interface SearchModalProps {
	open: boolean,
	onClose: () => void,
	title?: string,
	onVenueSelected?: (venue: Venue) => boolean;
	onSectionSelected?: (venue: Venue, date: VenueSection) => boolean;
	pageSize?: number,
	venueId?: number, // select a section of this venue
	section?: boolean, // search first venue and then select section
}

var SearchVenueSectionModal = (props: SearchModalProps) => {
	var [searching, setSearching] = useState<boolean>(false);
	var [searchInput, setSearchInput] = useState<string>('');
	var [searchResults, setSearchResults] = useState<Venue[]>(null);
	var [searchError, setSearchError] = useState<string>(null);
	var [sentQuery, setSentQuery] = useState<string>(null);
	var [pageVenue, setPageVenue] = useState<number>(0);
	var [pageSection, setPageSection] = useState<number>(0);
	var [sections, setSections] = useState<VenueSection[]>(null);
	var [selectedVenue, setSelectedVenue] = useState<Venue>(null);
	var [venueId, setVenueId] = useState<number>(props.venueId);
	var inputRef = useRef<HTMLInputElement>(null);
	const PAGE_SIZE = (props.pageSize || 15);

	useEffect(() => {
		if (props.open) {
			setSearchInput('');
			inputRef?.current?.focus();
			if (props.venueId) {
				viewSections(props.venueId, 0);
			}
		}
	}, [props.open]);

	var searchVenue = (setLoading: SetLoading, page?: number) => {
		if (searching) return;
		setSearching(true);
		setSentQuery(searchInput);
		setPageVenue(page || 0);
		setSearchError(null);
		API.getAllVenues(searchInput, page * PAGE_SIZE, PAGE_SIZE).then(res => {
			if (res.error) return setSearchError(res.message);
			setSearchResults((res.data as any[]) as Venue[]);
		}).catch(err => {
			setSearchError('Hubo un error inesperado buscando los recintos (LCL-1)');
		}).finally(() => {
			setSearching(false);
		});
	}

	var viewSections = (venue_id: number, page?: number) => {
		setSearching(true);
		setPageSection(page || 0);
		API.getAllVenueSections(venue_id, null, page * PAGE_SIZE, PAGE_SIZE).then(res => {
			if (res.error) return setSearchError(res.message);
			setSections(res.data);
		}).catch(err => {
			setSearchError('Hubo un error buscando las secciones de el recinto.');
		}).finally(() => {
			setSearching(false);
		})
	}

	var venueSelected = (venue: Venue) => {
		return () => {
			setVenueId(venue.venue_id)
			if (props.venueId || props.section) {
				viewSections(venue.venue_id, 0);
				setSelectedVenue(venue);
			} else {
				if (!props.onVenueSelected) return;
				var done = props.onVenueSelected(venue);
				if (done !== false) {
					props.onClose();
				}
			}
		}
	}

	var sectionSelected = (section_id: number) => {
		if (!props.onSectionSelected) return;
		var section = sections.find(a => a.section_id === section_id);
		if (!section) return;
		var done = props.onSectionSelected(selectedVenue, section);
		if (done !== false) {
			props.onClose();
		}
	}

	var onPageChange = (page: number) => {
		if (venueId) {
			setPageSection(page);
			viewSections(venueId, page);
		} else {
			setPageVenue(page);
			searchVenue(null, page);
		}
	}

	var onGoBack = () => {
		setSelectedVenue(null);
		setVenueId(null);
	}

	var hasPagination = ((searchResults?.length || 0) >= PAGE_SIZE || pageVenue != 0) || (venueId && ((sections?.length || 0) === PAGE_SIZE || pageSection != 0));
	var currentPage = venueId ? pageSection : pageVenue;
	var hasNext = venueId ? sections?.length >= PAGE_SIZE : searchResults?.length >= PAGE_SIZE;
	return <Modal open={props.open} onClose={props.onClose} size='tiny'>
		<Modal.Header>{props.title || (!venueId ? 'Buscar recinto' : (props.venueId ? `Seleccionar seccion - Recinto [${props.venueId}]` : 'Seleccionar seccion'))}</Modal.Header>
		<Modal.Content style={{ paddingBottom: hasPagination ? 0 : 20 }}>
			{!venueId ? (
				<Input ref={inputRef} value={searchInput} onChange={setSearchInput} placeholder='Buscar registros' button={(
					<Button text='Buscar' onClick={searchVenue} loading={searching} />
				)} onSubmit={searchVenue} icon='search' submitOnEnter />
			) : (selectedVenue ?
				<Input value={selectedVenue?.venue_name} readonly 
					button={props.section ? (<Button text='Regresar' onClick={onGoBack} color='black' />): null} />
					: null
			)}
			{searching ? (
				<Header loading text={`Cargando ${selectedVenue ? 'secciones' : 'recintos'}`} containerStyle={{ marginBottom: 50, marginTop: 50 }} />
			) : searchError ? (
				<Header text='Error' subtext={searchError} iconName='face-frown-open' style={{ marginTop: 30, marginBottom: 30 }} size='small' />
			) : (!searchResults && !sections) ? (
				null
			) : searchResults?.length === 0 ? (
				<Header text='Recintos no encontrados' subtext={`No se encontraron recintos con "${sentQuery}"`} iconName='face-frown-open' style={{ marginTop: 30, marginBottom: 30 }} size='small' />
			) : !selectedVenue ? (
				<table className="fr fitted selectable first bottom round striped divided table" style={{ marginTop: 20 }}>
					<thead>
						<tr>
							<th>ID</th>
							<th>Nombre</th>
						</tr>
					</thead>
					<tbody>
						{searchResults.map(a => (
							<tr key={`SMR-${a.venue_id}`} onClick={venueSelected(a)}>
								<td className='collapsing'>{a.venue_id}</td>
								<td>{a.venue_name}</td>
							</tr>
						))}
					</tbody>
				</table>
			) : (
				<Table
					fitted
					selectable
					striped
					divided
					style={{ marginTop: 20 }}
					className='first bottom round'
					headers={['ID', 'Nombre']}
					collapsingIndexes={[0, 2, 3, 4]}
					emptyText='No se encontraron secciones para este recinto.'
					onClick={v => sectionSelected(v[0])}
					data={sections.map(a => ([
						a.section_id, a.section_name
					]))}
				/>
			)}
			{((!!searchResults || !!sections) && hasPagination) && (
				<div style={{ justifyContent: 'flex-end', display: 'flex', marginBottom: 10 }}>
					<Pagination page={currentPage} disabled={searching} onPageChange={onPageChange} hasNext={hasNext} />
				</div>
			)}
		</Modal.Content>
	</Modal>
}

export default SearchVenueSectionModal;