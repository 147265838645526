import React, { useState, useEffect } from "react";
import { Button, Header, Table, Icon } from 'react-frontier';
import API from "../../API";
import { PDV, PDVZone, SetLoading, Zone } from "@arema/components/Classes";
import { SearchZoneModal } from "../../components";
import { bindClose } from "@arema/components/Util";
import moment from "moment";
import { useUser } from "../../AdminHooks";
import { Dropdown, Modal } from "semantic-ui-react";
import Toast from 'react-hot-toast';

enum Modals {
	'SEARCH_ZONE' = 1,
	'CONFIRM_DELETE' = 3,
}

var PdvZones = (props: { pdv_id: number, pdv: PDV }) => {
	var [zones, setZones] = useState<PDVZone[]>(null);
	var [selectDeleteZone, setDeleteSelectZone] = useState<PDVZone>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [modal, setModal] = useState<Modals>(null);
	var { user } = useUser()

	var loadPDVZones = () => {
		API.getPDVZones(props.pdv_id).then(res => {
			if (res.error) return setLoadError(res.message);
			setZones(res.data);
		}).catch(err => {
			setLoadError('Hubo un error cargando las zonas. (LCL-1)');
		});
	}

	useEffect(() => {
		if (!zones && !props.pdv.all_events) {
			loadPDVZones();
		} else if (props.pdv.all_events) {
			setZones([]);
		}
	}, []);

	var onSelectZone = (zone: Zone, setLoading: SetLoading) => {
		if (zones.some(z => z.zone_id === zone.zone_id)) {
			return Toast.error('Esta zona ya esta en el pdv');
		}
		setLoading(true);
		API.savePDVZone(props.pdv_id, zone.zone_id).then(z => {
			if (z.error) return Toast.error(z.message);
			setZones(zones => {
				let new_zones = [...zones];
				new_zones.push({
					zone_id: zone.zone_id,
					description: zone.description,
					zone_name: zone.zone_name,
					date_added: moment().unix(),
					admin_id: user.admin_id,
					admin_added: user.username,
				})
				return new_zones;
			});
			setModal(null);
			return Toast.success('Se guardo la zona correctamente');
		}).catch(() => {
			return Toast.error('Hubo un error al guardar la zona. (LCL-2)');
		}).finally(() => {
			setLoading(false);
		})
	}

	var onSelectDeleteZone = (zone: PDVZone) => {
		return () => {
			setDeleteSelectZone(zone);
			setModal(Modals.CONFIRM_DELETE);
		}
	}

	var deleteZone = (setLoading: SetLoading) => {
		setLoading(true);
		var zone_id = selectDeleteZone.zone_id;
		API.deletePDVZone(props.pdv_id, zone_id).then((res) => {
			if (res.error) return Toast.error(res.message);
			var newZones = [...zones].filter(z => z.zone_id !== selectDeleteZone.zone_id);
			setZones(newZones);
			setModal(null);
			return Toast.success('Se eliminó la zona correctamente.');
		}).catch(() => {
			return Toast.error('Error inesperado (LCL-4)');
		}).finally(() => {
			setLoading(false);
		});
	}

	if (loadError) return <Header text="Error" subtext={loadError} />
	if (!zones) return <Header loading text="Cargando zonas" />

	return <div>
		<Table
			striped
			title="Zonas"
			headers={['ID', 'Zona', 'Usuario', <Icon name='pen' />]}
			style={{ maxWidth: 500, margin: 'auto' }}
			empty={zones.length === 0}
			emptyText={props.pdv.all_events ? "El PDV tiene acceso a todos los eventos" : "No hay zonas en este PDV"}
			collapsingIndexes={[0, 2, 3]}
			button={!props.pdv.all_events && (
				<Button text="Agregar" size="tiny" color="black" iconName="plus-circle" onClick={bindClose(setModal, Modals.SEARCH_ZONE)} />
			)}
		>
			{zones.map(a => (
				<Table.Row
					key={a.zone_id}
					collapsingIndexes={[0, 2, 3]}
					data={[
						a.zone_id,
						a.zone_name,
						a.admin_added,
						<Dropdown icon={null} trigger={(
							<Button icon color='black' iconName="pen" size="tiny" />
						)}>
							<Dropdown.Menu>
								<Dropdown.Item icon='remove' text='Eliminar' onClick={onSelectDeleteZone(a)} />
							</Dropdown.Menu>
						</Dropdown>
					]}
				/>
			))}
		</Table>
		<SearchZoneModal
			onClose={bindClose(setModal)}
			open={modal === Modals.SEARCH_ZONE}
			onSelected={onSelectZone}
		/>
		<Modal open={modal === Modals.CONFIRM_DELETE} onClose={bindClose(setModal)} size="tiny">
			<Modal.Header>¿Eliminar zona del pdv?</Modal.Header>
			<Modal.Content>
				<Header text={`¿Eliminar la zona "${selectDeleteZone?.zone_name}" de este PDV?`} />
				<Table title="Detalles" striped>
					<Table.Row data={['ID', selectDeleteZone?.zone_id]} details/>
					<Table.Row data={['Nombre', selectDeleteZone?.zone_name]} details/>
					<Table.Row data={['Descripción', selectDeleteZone?.description]} details/>
				</Table>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={bindClose(setModal)} text="Cancelar" basic />
				<Button text="Eliminar" color="black" onClick={deleteZone} />
			</Modal.Actions>
		</Modal>
	</div>
}

export default PdvZones;