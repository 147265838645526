import React, { useEffect, useState } from 'react'
import { addCommas, bindClose } from '@arema/components/Util';
import { Button, Groupper, Header, Message, Stat, Table, Toolbar } from 'react-frontier'
import { Link } from 'react-router-dom';
import { ErrorHeader, NotFound } from '../../components';
import { Modal } from 'semantic-ui-react';
import { SetLoading } from '@arema/components/Classes';
import { useUser } from '../../AdminHooks';
import API from '../../API';
import moment from 'moment';
import classNames from 'classnames';
import Toast from 'react-hot-toast';

interface CorteInfo {
	corte_id: number,
	date_start: number,
	user_start: number,
	user_start_name: string,
	date_end: number,
	user_end: number,
	user_end_name: string,
	total_amount: number,
	total_count: number,
	cancelled: boolean,
	date_cancelled: number,
	orders: {
		order_id: number,
		event_name: string,
		cancelled: boolean,
		paid: boolean,
		tickets: number,
		total: number,
		expired: boolean,
	}[],
	events: {
		event_id: number,
		event_name: string,
		total_tickets: number,
		total_orders: number,
		total: number,
	}[]
}

enum Modals {
	CONFIRM_CLOSE_CORTE = 100,
}

function PdvCorteDetails(props: { pdv_id: number, params: { corte_id: number } }){
	var { user } = useUser()
	var [corte, setCorte] = useState<CorteInfo>(null);
	var [loadError, setloadError] = useState<string>(null);
	var [showModal, setShowModal] = useState<Modals>(null);

	useEffect(()=>{
		if(!Number.isNaN(props.pdv_id) && !Number.isNaN(props.params.corte_id)){
			loadData();
		}
	}, [props.params.corte_id])

	var loadData = ()=>{
		API.getPDVCorteInfo(props.pdv_id, props.params.corte_id).then((res)=>{
			if(res.error) return setloadError(res.message);
			setCorte(res.data)
		}).catch(()=>{
			setloadError('Error inesperado cargando el corte (LCL-1)');
		});
	}

	var closeCorte = (setLoading: SetLoading)=>{
		setLoading(true);
		API.closePDVCorte(props.pdv_id, props.params.corte_id).then((res)=>{
			if(res.error) return Toast.error(res.message);
			setShowModal(null);
			setCorte(corte=>{
				return {
					...corte,
					user_end_name: user.username,
					date_end: moment().unix()
				}
			})
			return Toast.success('Se ha cerrado el corte de manera correcta')
		}).catch(()=>{
			return Toast.error('Error inesperado cerrando el corte (LCL-2)');
		}).finally(()=>{
			setLoading(false);
		})
	}

	if(Number.isNaN(props.params.corte_id)){
		return <NotFound />
	}
	if(loadError){
		return <ErrorHeader error={loadError} />
	}
	if(!corte){
		return <Header loading text='Cargando corte' />
	}

	var total_tickets = 0;
	for (var i of corte.orders){
		if(i.cancelled || !i.paid) continue;
		total_tickets += i.total;
	}

	return (
		<div>
			<div style={{ maxWidth: 500, margin: 'auto' }}>
				<Stat.Group>
					<Stat label={'Ingresos'} value={addCommas(total_tickets)} />
					<Stat label={'Ventas'} value={addCommas(corte.orders.length, false)} />
				</Stat.Group>
			</div>
			<Groupper fitted width={400} title="Detalles" >
				{!corte.date_end && (
					<Toolbar fitted style={{ marginBottom: 0, borderBottom: 0 }} >
						<Toolbar.Item icon="cut" text='Cerrar Corte' color='black' onClick={bindClose(setShowModal, Modals.CONFIRM_CLOSE_CORTE)} /> 
					</Toolbar>
				)}

				<Table striped details className='last'>
					<Table.Row data={['ID Corte', corte.corte_id]} />
					<Table.Row data={['Estatus',
						<div className={classNames('fr label', {
							red: corte.cancelled,
							blue: !corte.cancelled && !corte.date_end,
							orange: !corte.cancelled && !!corte.date_end
						})}>
							{corte.cancelled ? 'Cancelado' : (corte.date_end ? 'Cerrado' : 'Activo')}
						</div>
					]} />
					<Table.Divider />
					<Table.Row data={['Fecha abierto', moment.unix(corte.date_start).format('DD/MM/YY HH:mm:ss')]} />
					<Table.Row>
						<Table.Cell>Usuario creador</Table.Cell>
						<Table.Cell>
							{corte.user_start_name}
							<div className="meta">
								<Button as={Link} to={`/admins/${corte.user_start}`} target='_blank' icon size='tiny' iconName='external-link' />
							</div>
						</Table.Cell>
					</Table.Row>
					{!!corte.date_end && <>
						<Table.Divider />
						<Table.Row data={['Fecha Cierre', moment.unix(corte.date_end).format('DD/MM/YY HH:mm:ss')]} />
						<Table.Row>
							<Table.Cell>Usuario cierre</Table.Cell>
							<Table.Cell>
								{corte.user_end_name}
								<div className="meta">
									<Button as={Link} to={`/admins/${corte.user_end}`} target='_blank' icon size='tiny' iconName='external-link' />
								</div>
							</Table.Cell>
						</Table.Row>
					</>}
				</Table>
			</Groupper>

			<Table
				striped
				title='Ordenes'
				style={{ maxWidth: 600, margin: 'auto', marginTop: 20 }}
				headers={['ID', 'Evento', 'Estatus', 'Boletos', 'Total']}
				centeredIndexes={[2, 3, 4]}
				collapsingIndexes={[0, 2, 3, 4]}
			>
				{corte.orders.map(ord=>(
					<Table.Row
						selectable
						as={Link}
						key={ord.order_id}
						centeredIndexes={[2, 3, 4]}
						collapsingIndexes={[0, 2, 3, 4]}
						to={`/orders/@${ord.order_id}`}
						target={'_blank'}
						compact
						data={[
							ord.order_id,
							ord.event_name,
							<div className={classNames('fr label', {
								red: ord.cancelled,
								transparent: !ord.cancelled && ord.expired && !ord.paid,
								green: !ord.cancelled && ord.paid,
								blue: !ord.cancelled && !ord.paid && !ord.expired,
							})}>
								{ord.cancelled ? 'Cancelado' : (ord.expired ? 'Expirado' : (ord.paid ? 'Pagado' : 'Pendiente'))}
							</div>,
							ord.tickets,
							addCommas(ord.total)
						]}
					/>)
				)}
			</Table>

			<Table
				title='Eventos Vendidos'
				style={{ maxWidth: 600, margin: 'auto', marginTop: 20 }}
				centeredIndexes={[2, 3]}
				collapsingIndexes={[0, 2, 3, 4]}
				headers={['ID', 'Evento', 'Ordenes', 'Boletos', 'Total']}
			>
				{corte.events.map(ev=>(
					<Table.Row
						centeredIndexes={[2, 3, 4]}
						data={[ev.event_id, ev.event_name, addCommas(ev.total_orders, false), addCommas(ev.total_tickets, false), addCommas(ev.total)]}
						key={ev.event_id}
					/>)
				)}
			</Table>

			<Modal open={showModal === Modals.CONFIRM_CLOSE_CORTE} onClose={bindClose(setShowModal)} size="mini">
				<Modal.Header>Cerrar Corte</Modal.Header>
				<Modal.Content>
					<Header text={'¿Cerrar este corte?'} />
					<Message type='info' centered>
						Al cerrar el corte no se podrán crear mas ventas en este corte y el corte no podrá ser abierto nuevamente
					</Message>
					<Table
						details
						striped
						title={`Corte #${corte.corte_id}`}
						titleSize='small'
						style={{ marginTop: 15 }}
						data={[
							['ID', corte.corte_id],
							['Fecha inicio', moment.unix(corte.date_start).format('YYYY-MM-DD HH:mm')],
							['Usuario creador', corte.user_start_name]
						]}
					/>
				</Modal.Content>
				<Modal.Actions>
					<Button onClick={bindClose(setShowModal)} text="Cancelar" basic />
					<Button text="Cerrar corte" color="black" onClick={closeCorte} />
				</Modal.Actions>
			</Modal>
		</div>
	)
}

export default PdvCorteDetails