import React, { useEffect, useState } from 'react';
import { NotFound, SidebarContainer } from '../../components';
import { useParams } from 'react-router-dom';
import { UserAdmin } from '@arema/components/Classes';
import { useTitle } from '@arema/components/Hooks';
import { useUser } from '../../AdminHooks';
import { PDV_ACCESS_USER } from '../CatalogList';
import Catalog from '../Catalog';
import UserDetails from './UserDetails';
import UserSales from './UserSales';

var UserContainer = ()=>{
	var { setTitle } = useTitle();
	var { user, hasAccess } = useUser();
	var [userData, setUserData] = useState<UserAdmin>(null);
	var params = useParams<{ id: string }>();
	
	if(!params || !params.id || Number.isNaN(parseInt(params.id))){
		return <NotFound />
	}
	
	return <SidebarContainer 
		header={'Usuario'}
		routes={[
			{ url: `/admins/${params.id}`, component: UserDetails },
			{ url: `/admins/${params.id}/ventas`, component: UserSales },
			{ url: `/admins/${params.id}/logins`, component: UserDetails },
			{ url: `/admins/${params.id}/access`, render: <Catalog options={PDV_ACCESS_USER(user, hasAccess, params.id)} key={'user-access'} /> },
		]}
		routeParams={{
			onUserData: (ud: UserAdmin)=>{
				setUserData(ud);
				setTitle(ud.username.toUpperCase())
			}
		}}
		getTitle={()=>{
			return userData ? userData.username.toUpperCase() : 'Usuario'
		}}
		items={[
			{ text: 'Usuario', 		icon: 'user', 					url: `/admins/${params.id}` },
			{ text: 'Accesos', 		icon: 'history', 				url: `/admins/${params.id}/access` },
			// { text: 'Ventas', 	icon: 'cash register', 		url: `/admins/${params.id}/ventas` },
			// { text: 'Accesos', 	icon: 'sign in', 				url: `/admins/${params.id}/logins` },
		]}
	/>
}

export default UserContainer;