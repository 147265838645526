import React, { useState, useEffect } from "react";
import { Header } from 'react-frontier';
import { PAGE_PUBLIC } from "../../AdminConfig";
import { useParams } from "react-router-dom";
import { EventData } from "@arema/components/Classes";
import { SidebarContainer } from "../../components";
import { useTitle } from "@arema/components/Hooks";
import { useUser } from "../../AdminHooks";

import EventDetails from './EventDetails';
import EventOptions from './EventOptions';
import EventDateList from './EventDateList';
import EventDate from './EventDate';
import EventDateCreate from './EventDateCreate';
import EventBalance from "./EventBalance";
import EventFormList from "./EventFormList";
import EventDateFormList from "./EventDateFormList";
import EventDateCreateQuestions from "./EventDateCreateQuestions";
import API from "../../API";

var EventContainer = ()=>{
	// var navigate = useNavigate();
	// var location = useLocation();
	// var [section, setSection] = useState<string>(params['*']);
	var params = useParams<{ id: string, '*': string }>();
	var date_id = params['*'].split('/')[1];
	var [eventError, setEventError] = useState<string>(null);
	var [event, setEvent] = useState<EventData>(null);
	var { setTitle } = useTitle();
	var { user, hasAccess } = useUser();

	useEffect(()=>{
		if(!event){
			API.getEvent(parseInt(params.id), true).then(res=>{
				if(res.error) return setEventError(res.message);
				setEvent({ 
					...res.data,
					commission_return: res.data.commission_return ? res.data.commission_return*100 : null,
				});
				setTitle(res.data.event_name)
			}).catch(err=>{
				return setEventError('Hubo un error inesperado cargando los datos del evento (LCL-1)');
			});
		}
	}, [])

	if(eventError){
		return <Header text="Error" subtext={eventError} iconName='face-frown-open' />
	}else if(!event){
		return <Header loading text="Cargando evento" size={'small'} loaderSize="large" />
	}
	
	var onEditEvent = (ev: EventData)=>{
		setEvent(ev);
	}

	var route_params = {
		event: event,
		onEditEvent: onEditEvent,
		// handleRender: handleRoute,
		// render: navigateContainer,
	}

	return <SidebarContainer 
		header={'Editar evento'}
		routes={[
			{ url: `/events/:eventid`,												component: EventDetails, },
			{ url: `/events/:eventid/dates`, 									component: EventDateList },
			{ url: `/events/:eventid/dates/create`,							component: EventDateCreate },
			{ url: `/events/:eventid/dates/:id`, 								component: EventDate },
			{ url: `/events/:eventid/balance`,									component: EventBalance },
			{ url: `/events/:eventid/options`,									component: EventOptions },
			{ url: `/events/:eventid/forms`,										component: EventFormList  },
			{ url: `/events/:eventid/forms/:date_id`,							component: EventDateFormList },
			{ url: `/events/:eventid/forms/:date_id/q/:question_id`,		component: EventDateCreateQuestions },
		]}
		routeParams={route_params}
		getTitle={(r, ix)=>{
			return event.event_name;
		}}
		items={[
			{ text: 'Detalles', 				icon: 'edit', 							url: `/events/${params.id}` },
			{ text: 'Calendarios', 			icon: 'calendar', 					url: `/events/${params.id}/dates` },
			{ text: 'Estado de cuenta', 	icon: 'file invoice dollar', 		url: `/events/${params.id}/balance` },
			{ text: 'Opciones', 				icon: 'cog', 							url: `/events/${params.id}/options` },
			{ text: 'Formularios',			icon: 'question circle',			url: `/events/${params.id}/forms` },
			{ text: 'Ver evento',			icon: 'eye',							url: `${PAGE_PUBLIC}/e/${params.id}`, target: '_blank', selectable: false }
		]}
		contentHeader={(
			<Header text={event.event_name} subtext={event.published ? 'Publicado' : 'No publicado'} style={{ marginTop: 0, paddingTop: 0 }} />
		)}
	/>
}

export default EventContainer;