import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigationType, NavigationType } from "react-router-dom";
import { TicketForm } from "@arema/components";
import { Button, Field, Groupper, Input, Items, Message, Header, Table, Stat, Toolbar } from 'react-frontier'
import { Icons, addCommas, bindClose, bindFormChange, bindSemantic, formatSeatNumber, groupBy, uniqueArray } from "@arema/components/Util";
import { DeliveryMethod, Order, PaymentMethod, QuestionType, SetLoading, Ticket, TicketQuestion, TicketUse, TicketFormPayload, CancelReason, OrderDepositType, SeatStatus, Location, SeatmapSeat } from "@arema/components/Classes";
import { Checkbox, Dropdown, Icon, Modal } from "semantic-ui-react";
import { useTitle } from "@arema/components/Hooks";
import { UpdateSeatTicketModal, PaymentInfoModal, OrderInvoicesModal, LocationModal, OrderEmailsModal } from "../../components";
import { useUser } from "../../AdminHooks";
import Validator from "@arema/components/Validator";
import Toast from 'react-hot-toast';
import moment from "moment";
import API from "../../API";
import classNames from "classnames";
import UserAccess from "../../UserAccess";

import '../../style/order.scss';

interface OrderMove{
	order_hash: string,
	force: boolean,
	show_force: boolean
}

interface ToolbarItemProps{
	onClick?: ()=>void,
	text: string,
	icon: Icons
}
var ToolbarItem = (props: ToolbarItemProps)=>{
	return <div className="item" onClick={props.onClick}>
		<i className={`${props.icon} icon`}></i> {props.text}
	</div>	
}


var TicketItem = (props: { ticket: Ticket, price?: boolean })=><>
	<div className="left">
		<div style={{ fontWeight: 'bold' }}>{props.ticket.ticket_hash}</div>
		<div style={{ fontSize: 14 }}>{props.ticket.section_name} - {props.ticket.price_name}</div>
	</div>
	{props.ticket.seat_id ? (
		<div className="right" style={{ textAlign: 'right' }}>
			<div style={{ fontWeight: 'bold', fontSize: 18 }}>{formatSeatNumber(props.ticket.seat_row, props.ticket.seat_number)}</div>
			{props.ticket.seat_section && props.ticket.seat_section.length>0 ? (
				<div style={{ fontSize: 12 }}>{props.ticket.seat_section}</div>
			) : null}
		</div>
	) : null}
	{!!props.price && (
		<div className="right" style={{ fontSize: 18, marginLeft: 15 }}>
			${addCommas(props.ticket.ticket_cost)}
		</div>
	)}
</>

enum MODAL{
	RESEND = 100,
	SET_PAID = 101,
	EXTEND = 102,

	ORDER_EDIT = 200,
	ORDER_TOTALS = 201,
	ORDER_PAYMENT_EDIT = 202,
	ORDER_DELIVERY_EDIT = 203,
	ORDER_INVOICES = 204,
	ORDER_LOCATION = 205,
	ORDER_EMAILS = 206,

	ORDER_REFUND = 300,
	ORDER_REFUND_REQUEST = 301,
	
	VIEW_PDF = 400,
	CONVERT_FREE = 401,
	CONCILIA = 402,
	MOVE_TICKETS = 403,
	VIEW_RECEIPT = 404,
	REVIVE_TICKETS = 405,
	DELETE_CONCILIA = 406,

	TICKET_OPTIONS = 500,
	TICKET_DETAILS = 501,
	TICKET_FORM = 502,
	TICKET_USES = 503,
	TICKET_NEW_USE = 504,
	TICKET_EDIT = 505,
	TICKET_USER = 506,
	TICKET_UPDATE_SEAT = 507,
	TICKET_HIDE = 508,

	CANCEL_ORDER = 900,
	EXPIRE_ORDER = 901,

	PAYMENT_INFO = 600,
}

interface RefundForm{
	commission: boolean,
	delivery: boolean,
	payment: boolean,
	email: string,
	fake?: boolean,
	send_email?: boolean,
}

var OrderView = ()=>{
	var { hash } = useParams();
	var { user, hasAccess, dev } = useUser();
	var { setTitle } = useTitle();
	var navType = useNavigationType();
	var [order, setOrder] = useState<Order>(null);
	var [loadError, setLoadError] = useState(null);
	var [modalPrompts, setModalPrompts] = useState<string[]>(null);
	var [ticket, setTicket] = useState<Ticket>();
	var [ticketForm, setTicketForm] = useState<TicketQuestion[]>(null);
	var [orderPdf, setOrderPdf] = useState<string>(null);
	var [orderEdit, setOrderEdit] = useState<Order>(null);
	var [moveOrder, setMoveOrder] = useState<OrderMove>(null);
	var [confirmCheck, setConfirmCheck] = useState<boolean>(false);
	var [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>(null);
	var [deliveryMethods, setDeliveryMethods] = useState<DeliveryMethod[]>(null);
	var [ticketUses, setTicketUses] = useState<TicketUse[]>(null);
	var [ticketUseSelected, setTicketUseSelected] = useState<TicketUse>(null);
	var [ticketsSelected, setTicketsSelected] = useState<number[]>([]);
	var [extendAmount, setExtendAmount] = useState<number>(null);
	var [ticketUseDate, setTicketUseDate] = useState<number>(null);
	var [shownModal, setShownModal] = useState<MODAL>(null);
	var [selectedDeposit, setSelectedDeposit] = useState<number>(null);
	var [newTicketSeat, setNewTicketSeat] = useState<SeatmapSeat>(null);
	var [refundForm, setRefundForm] = useState<RefundForm>(null);
	var [paymentIdSelected, setPaymentIdSelected] = useState<number>(null);
	var [depositForm, setDepositForm] = useState<{ 
		amount: string, 
		payment_id: number,
		type: OrderDepositType, 
		payment_method: number, 
		payment_method_name: string, 
		date_deposit: number,
		external_id?: string,
	}>(null);

	useEffect(()=>{
		if(!order){
			var order_id = null;
			if(hash.startsWith('@')){
				order_id = parseInt(hash.substring(1));
				if(Number.isNaN(order_id)) order_id = null;
			}
			
			API.getOrder(order_id ? null : hash, order_id, {
				concilia: true,
				emails: true,
				payments: true,
				refunds: true,
				tickets: true,
				uses: true,
				users: true,
			}, true, navType===NavigationType.Pop).then(res=>{
				if(res.error) return setLoadError(res.message);
				setOrder(res.data);
				setTitle(`Orden ${res.data.order_hash}`);
			}).catch(err=>{
				return setLoadError('Hubo un error inesperado cargando las ordenes (LCL-1)');
			});
		}
	}, []);

	if(!order || loadError) return (
		<Header 
			loading={!loadError} 
			subtext={loadError} 
			text={loadError ? 'Error' : 'Cargando orden...'} 
			iconName={loadError ? "face-frown-open" : null} 
			containerStyle={{ marginTop: 15 }} 
		/>
	)

	var showTicketOptions = (t?: Ticket)=>{
		return ()=>{
			if(!t) return;
			setModalPrompts(null);
			setTicket(t);
			setShownModal(MODAL.TICKET_OPTIONS)
		}
	}

	var showModal = (modal: MODAL)=>{
		return ()=>{
			setShownModal(modal);
			setConfirmCheck(false);
			setTicketsSelected([]);
			setMoveOrder({ order_hash: '', force: false, show_force: false });
			setModalPrompts(null);
		}
	}

	var showTicketForm = ()=>{
		setTicketForm(null);
		setShownModal(MODAL.TICKET_FORM);
		API.getTicketForm(ticket.ticket_id).then(res=>{
			if(res.error){
				setShownModal(null);
				return Toast.error(res.message);
			}
			setTicketForm(res.data);
		}).catch(err=>{
			setShownModal(null);
			return Toast.error('Hubo un error inesperado cargando el formulario del boleto (LCL-1)');
		})
	}

	var showTicketUses = ()=>{
		setTicketUses(null);
		setShownModal(MODAL.TICKET_USES);
		API.getTicketUses(ticket.ticket_id).then(res=>{
			if(res.error){
				setShownModal(null);
				return Toast.error(res.message);
			}
			setTicketUses(res.data);
		}).catch(err=>{
			setShownModal(null);
			return Toast.error('Hubo un error inesperado cargando los usos del boleto (LCL-1)');
		})
	}
	
	var showUseTicket = ()=>{
		setTicketUseDate(moment().unix());
		setShownModal(MODAL.TICKET_NEW_USE);
	}

	var resend = (setLoading: SetLoading)=>{
		if(order.cancelled) return Toast.error('No se puede reenviar el correo de una orden cancelada.');
		setLoading(true);
		API.resendEmail(order.order_id).then(res=>{
			if(res.error) return Toast.error(res.message);
			Toast.success('Se ha reenviado el correo al cliente.');
			setShownModal(null);
		}).catch(err=>{
			Toast.error('Hubo un error inesperado reenviando el correo. (LCL-1)');
		}).finally(()=>{
			setLoading(false);
		})
	}

	var setPaid = (setLoading: SetLoading)=>{
		if(order.paid || order.cancelled) return;
		setLoading(true);
		setModalPrompts(null);
		API.setOrderPaid(order.order_id).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Se ha reenviado el correo al cliente.');
			setShownModal(null);
			var ord = {...order};
			var now = moment().unix();
			ord.paid = true;
			ord.date_paid = now;
			for(var i of ord.tickets){
				if(i.cancelled) continue;
				i.paid = true;
				i.date_paid = now;
			}
			setOrder(ord);
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado marcando la orden como pagada. (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var sendExtend = (setLoading: SetLoading)=>{
		if(order.expired && !hasAccess(3903)) return;
		if(!extendAmount && extendAmount<=0) return setModalPrompts(['Favor de seleccionar el tiempo a agregar']);
		setLoading(true);
		API.extendOrder(order.order_id, extendAmount).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			var ord = {...order};
			ord.expired = false;
			ord.date_expiration = moment().add(extendAmount, 'second').unix();
			setOrder(ord);
			setShownModal(null);
			Toast.success('Se ha extendido el tiempo de pago de la orden');
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado extendiendo la orden. (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var viewOrderPdf = ()=>{
		setShownModal(MODAL.VIEW_PDF);
		if(!orderPdf){
			API.getOrderPdf(order.order_id).then(res=>{
				if(res.error || !res.data || !res.data.url){
					Toast.error(res.message || 'Hubo un error inesperado cargando el PDF de la orden (LCL-2)');
					return setShownModal(null);
				}
				setOrderPdf(res.data.url);
			}).catch(err=>{
				Toast.error('Hubo un error inesperado cargando el PDF de la orden (LCL-1)');
				return setShownModal(null);
			});
		}
	}

	var showOrderEdit = ()=>{
		setModalPrompts(null);
		setOrderEdit({...order});
		setShownModal(MODAL.ORDER_EDIT);
	}

	var sendOrderEdit = (setLoading: SetLoading)=>{
		var { valid, prompts } = Validator(orderEdit, {
			first_name: [{
				rule: 'length', params: [3, 32], label: 'Nombre'
			}],
			last_name: [{
				rule: 'length', params: [3, 32], label: 'Apellido'
			}],
			email: [{
				rule: 'email', label: 'Correo electrónico'
			}]
		});
		setModalPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.editOrder(order.order_id, {
			first_name: orderEdit.first_name, 
			last_name: orderEdit.last_name, 
			email: orderEdit.email,
		}).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			var ord = {...order};
			ord.email = orderEdit.email;
			ord.first_name = orderEdit.first_name;
			ord.last_name = orderEdit.last_name;
			setOrder(ord);
			setShownModal(null);
			setOrderEdit(null);
			Toast.success('Se ha editado la orden.');
		}).catch(err=>{
			return setModalPrompts(['Hubo un error inesperado editando la orden (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var showTotalsEdit = ()=>{
		setModalPrompts(null);
		setOrderEdit({...order});
		setShownModal(MODAL.ORDER_TOTALS);
	}

	var sendTotalsEdit = (setLoading: SetLoading)=>{
		var { valid, prompts } = Validator(orderEdit, {
			delivery_cost: [{
				rule: 'float', label: 'Entrega'
			}]
		});
		setModalPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.editOrder(order.order_id, {
			delivery_cost: orderEdit.delivery_cost,
		}).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			var ord = {...order};
			ord.delivery_cost = parseFloat(orderEdit.delivery_cost.toString());
			setOrder(ord);
			setShownModal(null);
			setOrderEdit(null);
			Toast.success('Se ha editado la orden.');
		}).catch(err=>{
			return setModalPrompts(['Hubo un error inesperado los costos de la orden (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var getPaymentMethods = ()=>{
		if(!paymentMethods){
			API.getEventsQL(['payment_methods']).then(res=>{
				if(res.error){
					Toast.error(res.message);
					return setShownModal(null);
				}
				setPaymentMethods(res.data.payment_methods);
			}).catch(err=>{
				setShownModal(null);
				return Toast.error('Hubo un error inesperado cargando los métodos de pago. (LCL-1)');
			})
		}
	}

	var showPaymentMethodChange = ()=>{
		setOrderEdit({...order});
		setShownModal(MODAL.ORDER_PAYMENT_EDIT);
		getPaymentMethods();
	}

	var sendPaymentChange = (setLoading: SetLoading)=>{
		if(order.payment_method===orderEdit.payment_method){
			return Toast.success('El método de pago no ha cambiado.');
		}
		var { valid, prompts } = Validator(orderEdit, {
			payment_method: [{
				rule: 'number', label: 'Forma de pago'
			}],
		});
		setModalPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.editOrder(order.order_id, {
			payment_method: orderEdit.payment_method
		}).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			var ord = {...order};
			ord.payment_method = parseFloat(orderEdit.payment_method.toString());
			if(paymentMethods){
				try{
					var pm = paymentMethods.find(a=>a.method_id==orderEdit.payment_method);
					if(pm){
						ord.payment_method_name = pm.method_name;
						ord.payment_method_name_internal = pm.method_name_internal;
					}
				}catch(e){}
			}
			setOrder(ord);
			setShownModal(null);
			setOrderEdit(null);
			Toast.success('Se ha editado la orden.');
		}).catch(err=>{
			return setModalPrompts(['Hubo un error inesperado los costos de la orden (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var showDeliveryChange = ()=>{
		setOrderEdit({...order});
		setShownModal(MODAL.ORDER_DELIVERY_EDIT);
		if(!deliveryMethods){
			API.getEventsQL(['delivery_methods']).then(res=>{
				if(res.error){
					Toast.error(res.message);
					return setShownModal(null);
				}
				setDeliveryMethods(res.data.delivery_methods);
			}).catch(err=>{
				setShownModal(null);
				return Toast.error('Hubo un error inesperado cargando los formas de entrega. (LCL-1)');
			})
		}
	}

	var sendDeliveryChange = (setLoading: SetLoading)=>{
		if(order.delivery_id===orderEdit.delivery_id){
			return Toast.success('La forma de entrega no ha cambiado.');
		}
		var { valid, prompts } = Validator(orderEdit, {
			delivery_id: [{
				rule: 'number', label: 'Entrega'
			}],
		});
		setModalPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.editOrder(order.order_id, {
			delivery_id: orderEdit.delivery_id
		}).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			var ord = {...order};
			ord.delivery_id = parseFloat(orderEdit.delivery_id.toString());
			if(deliveryMethods){
				try{
					var dm = deliveryMethods.find(a=>a.delivery_id==orderEdit.delivery_id);
					if(dm){
						ord.delivery_name = dm.method_name;
						ord.delivery_needs_location = dm.needs_location;
					}
				}catch(e){}
			}
			setOrder(ord);
			setShownModal(null);
			setOrderEdit(null);
			Toast.success('Se ha editado la orden.');
		}).catch(err=>{
			return setModalPrompts(['Hubo un error inesperado los costos de la orden (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var sendConvertFree = (setLoading: SetLoading)=>{
		if(!confirmCheck) return;
		setLoading(true);
		API.convertFree(order.order_id).then(res=>{
			if(res.error) return Toast.error(res.message);
			var ord = {...order};
			ord.total_tickets = 0;
			ord.payment_cost = 0;
			ord.delivery_cost = 0;
			ord.commission = 0;
			if(ord.tickets){
				for(var i of ord.tickets){
					i.ticket_cost = 0;
					i.commission = 0;
				}
			}
			if(ord.payments){
				for(var p of ord.payments){
					p.total = 0;
				}
			}
			setOrder(ord);
			Toast.success('Se ha convertido la orden en cortesía');
			setShownModal(null);
		}).catch(err=>{
			Toast.error('Hubo un error inesperado convirtiendo en cortesía. (LCL-1)')
		}).finally(()=>{
			setLoading(false);
		})
	}

	var sendCancelOrder = (setLoading: SetLoading)=>{
		if(!confirmCheck) return;
		setLoading(true);
		API.cancelOrder(order.order_id).then(res=>{
			if(res.error) return Toast.error(res.message);
			var ord = {...order};
			ord.cancelled = true;
			if(ord.tickets){
				for(let i of ord.tickets){
					i.cancelled = true;
				}
			}
			if(ord.payments){
				for(let i of ord.payments){
					i.cancelled = true;
				}
			}
			setOrder(ord);
			setShownModal(null);
			Toast.success('Se ha cancelado la orden.');
		}).catch(err=>{
			return Toast.error('Hubo un error inesperado cancelando la orden. (LCL-1)');
		}).finally(()=>{
			setLoading(false);
		})
	}

	var sendExpireOrder = (setLoading: SetLoading)=>{
		if(!confirmCheck) return;
		setLoading(true);
		API.expireOrder(order.order_id).then(res=>{
			if(res.error) return Toast.error(res.message);
			var ord = {...order};
			ord.expired = true;
			setOrder(ord);
			setShownModal(null);
			Toast.success('Se ha expirado la orden.');
		}).catch(err=>{
			return Toast.error('Hubo un error inesperado expirando la orden. (LCL-1)');
		}).finally(()=>{
			setLoading(false);
		})
	}

	var sendReviveTickets = (setLoading: SetLoading)=>{
		if(ticketsSelected.length==0) return;
		setLoading(true);
		API.reviveTickets(order.order_id, ticketsSelected).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			if(!res.data || res.data.length==0) return setModalPrompts(['No se pudo revivir ninguno de los boletos. (LCL-2)']);
			var ord = {...order};
			for(var i of res.data){
				var tk = ord.tickets.findIndex(a=>a.ticket_id==i);
				if(tk==-1) continue;
				ord.tickets[tk].cancelled = false;
			}
			ord.cancelled = false;
			setOrder(ord);
			setShownModal(null);
			Toast.success('Se han revivido los boletos seleccionados.');
		}).catch(err=>{
			return setModalPrompts(['Hubo un error inesperado reviviendo los boletos. (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var sendMoveTickets = (setLoading: SetLoading)=>{
		if(ticketsSelected.length==0) return setModalPrompts(['Favor de seleccionar los boletos a mover.']);
		if(moveOrder.order_hash.length==0) return setModalPrompts(['Favor de ingresar la orden a donde se moverán los boletos.']);
		setLoading(true);
		API.moveTickets(ticketsSelected, moveOrder.order_hash, moveOrder.force).then(res=>{
			if(res.error){
				if(res.code=='A-ORDMVCN'){
					setMoveOrder({
						...moveOrder,
						show_force: true
					})
				}
				return setModalPrompts([res.message]);
			}
			setShownModal(null);
			setMoveOrder({ order_hash: '', show_force: false, force: false });

			var ord = {...order};
			ord.tickets = ord.tickets.filter(a=>ticketsSelected.indexOf(a.ticket_id)==-1);
			var cancelled = true;
			for(var i of ord.tickets){
				if(!i.cancelled){
					cancelled = false;
					break;
				}
			}
			ord.cancelled = cancelled;
			setOrder(ord);
			Toast.success('Se han movido los boletos a la orden ingresada.');
		}).catch(err=>{
			return setModalPrompts(['Hubo un error inesperado moviendo los boletos. (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var sendFormEdit = (setLoading: SetLoading)=>{
		var data : TicketFormPayload[] = ticketForm.map(a=>{
			var data : TicketFormPayload = { question_id: a.question_id, required: a.required }
			if(a.question_type==QuestionType.FILE_IMAGE || a.question_type==QuestionType.FILE_OTHER){
				if(a.files){
					data.files = a.files;
				}else data.files = null;
			}else if(a.question_type==QuestionType.MULTIPLE || a.question_type==QuestionType.SELECTION){
				data.selected = a.selected && a.selected.length>0 ? a.selected : (a.value_id ? [a.value_id] : []);
			}else{
				data.answer = a.answer;
			}
			return data;
		});

		for(var d of data){
			if(d.required && ((!d.answer || d.answer.length==0) && (!d.files || d.files.length==0) && d.selected.length==0)){
				return setModalPrompts(['Uno o mas preguntas requeridas del formulario están faltantes.']);
			}
		}

		setModalPrompts(null);
		var formdata = new FormData();
		for(var i=0; i<data.length; i++){
			var a = data[i];
			if((a.files && a.files.length>0) || (a.selected && a.selected.length>0) || a.answer){
				formdata.append(`answers[${i}][question_id]`, a.question_id.toString());
			}else continue;
			if(a.files){
				a.files.forEach((v, ix)=>{
					formdata.append(`answers[${i}][files][${ix}]`, v);
				})
			}else if(a.selected && a.selected.length>0){
				a.selected.forEach((v, ix)=>{
					formdata.append(`answers[${i}][selected][${ix}]`, v.toString());
				});
			}else if(a.answer){
				formdata.append(`answers[${i}][answer]`, a.answer);
			}
		}
		setLoading(true);
		API.editTicketForm(order.order_id, ticket.ticket_id, formdata).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Se ha guardado el formulario.');
			showTicketForm()
		}).catch(err=>{
			return setModalPrompts(['Hubo un error inesperado guardando el formulario (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var sendTicketEdit = (setLoading: SetLoading)=>{
		var { valid, prompts } = Validator(ticket, {
			ticket_cost: [
				{ rule: 'float', prompt: 'El precio del boleto no es válido.' },
			],
			commission: [
				{ rule: 'float', prompt: 'El precio del boleto no es válido.' },
			]
		})
		setModalPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.editTicket(ticket.ticket_id, ticket.ticket_cost, ticket.commission).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Se ha guardado el precio del boleto.');
			setShownModal(null);
			var ord = {...order};
			var tx = ord.tickets.findIndex(a=>a.ticket_id==ticket.ticket_id);
			if(tx==-1) return;
			ord.tickets[tx].ticket_cost = parseFloat(ticket.ticket_cost.toString());
			ord.tickets[tx].commission = parseFloat(ticket.commission.toString());
			setOrder(ord);
		}).catch(err=>{
			return setModalPrompts(['Hubo un error inesperado editando el precio del boleto (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var sendUseTicket = (setLoading: SetLoading)=>{
		if(!ticket) return;
		if(!moment.unix(ticketUseDate).isValid()) return setModalPrompts(['La fecha de uso no es válida.']);
		setLoading(true);
		API.ticketUse(ticket.ticket_id, ticketUseDate).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Se ha usado el boleto.');
			setShownModal(null);
			var ord = {...order};
			var tx = ord.tickets.findIndex(a=>a.ticket_id==ticket.ticket_id);
			if(tx!=-1) ord.tickets[tx].uses = ord.tickets[tx].uses ? ord.tickets[tx].uses+1 : 1;
			setOrder(ord);
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado usando el boleto. (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var deleteTicketUse = (setLoading: SetLoading)=>{
		if(!ticketUseSelected) return;
		setLoading(true);
		API.deleteTicketUse(ticket.ticket_id, ticketUseSelected.use_id).then(res=>{
			if(res.error) return setModalPrompts([res.message])
			var ord = {...order};
			var tx = ord.tickets.findIndex(a=>a.ticket_id==ticket.ticket_id);
			if(tx!=-1) ord.tickets[tx].uses = ord.tickets[tx].uses ? ord.tickets[tx].uses-1 : 1;
			setOrder(ord);
			showTicketUses();
			setTicketUseSelected(null);
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado eliminando el uso del boleto. (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var showDeleteDeposit = (deposit_id: number)=>{
		return ()=>{
			if(!hasAccess(UserAccess.CONTABILIDAD.CANCEL_DEPOSIT)){
				return Toast.error('No tienes acceso a realizar esta acción')
			}
			setSelectedDeposit(deposit_id);
			showModal(MODAL.DELETE_CONCILIA)();
		}
	}

	var showCreateDeposit = ()=>{
		var pm = order.payments.find(a=>a.paid && !a.cancelled);
		var now = moment();
		now.set('hour', 12);
		now.set('minute', 0);
		now.set('second', 0);
		setDepositForm({ 
			amount: null,
			type: null,
			payment_id: null,
			payment_method: pm?.payment_method,
			payment_method_name: pm?.method_name_internal,
			date_deposit: now.unix(),
			external_id: ''
		});
		showModal(MODAL.CONCILIA)();
		getPaymentMethods();
	}

	var deleteDeposit = (setLoading: SetLoading)=>{
		if(!selectedDeposit) return;
		setLoading(true);
		setModalPrompts(null);
		API.deleteDeposit(selectedDeposit).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			if(order && order.concilia){
				var ord = {...order};
				ord.concilia = ord.concilia.filter(a=>a.deposit_id!=selectedDeposit);
				setOrder(ord);
			}
			setSelectedDeposit(null);
			setShownModal(null);
			Toast.success('Se ha eliminado la conciliación seleccionada.');
		}).catch(err=>{
			return setModalPrompts(['Hubo un error inesperado cancelando la conciliación (LCL-1)']);
		})
	}

	var onTicketChangeSeat = (seat: SeatmapSeat) => {
		if(seat.seat_status!==SeatStatus.FREE || (!!seat.ticket_id && seat.ticket_id !== ticket.ticket_id)){
			Toast.error('Esta butaca no esta diponible');
			return;
		}
		if (seat.seat_id === ticket.seat_id) {
			Toast.error('No se pueden seleccionar la misma butaca.');
			return;
		}
		setNewTicketSeat(seat);
	}

	var onSaveNewTicketSeat = (setLoading: SetLoading) => {
		setLoading(true);
		API.updateTicketSeat(ticket.ticket_id, newTicketSeat.seat_id).then(res => {
			if(res.error) return Toast.error(res.message);
			//setShownModal(null);
			//setNewTicketSeat(null);
			//setTicket(null);
			const newOrder = {...order};
			newOrder.tickets = order.tickets.map(t => {
				if (t.ticket_id === ticket.ticket_id) {
					t.seat_id = newTicketSeat.seat_id;
					t.seat_number = newTicketSeat.seat_number;
					t.seat_row = newTicketSeat.seat_row;
					t.seat_section = newTicketSeat.seat_section;
				}
				return t;
			});
			setOrder(newOrder);
			// TODO: Actualizar butaca local
			Toast.success('Se ha cambiado la butaca del ticket.');
		}).catch(err=>{
			return Toast.error('Hubo un error inesperado al cambiair la butaca (LCL-1)');
		}).finally(()=>{
			setLoading(false);
		})
	}

	var createDeposit = (setLoading: SetLoading)=>{
		if(!hasAccess(UserAccess.CONTABILIDAD.ADD_DEPOSIT)) return;
		var { valid, prompts } = Validator(depositForm || {}, {
			amount: [{
				rule: 'min', params: [1], prompt: 'La cantidad del deposito es inválida',
			}],
			payment_id: [{
				rule: 'empty', prompt: 'Favor de seleccionar el método de pago',
			}],
			type: [{
				rule: 'empty', prompt: 'Favor de seleccionar el tipo de deposito',
			}],
		});
		setModalPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.createDeposit(depositForm.payment_id, parseFloat(depositForm.amount), depositForm.date_deposit, depositForm.type, depositForm.external_id).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			if(!res.data.deposit_id) return setModalPrompts(['Hubo un error inesperado creando la conciliación (LCL-2)']);
			var ord = {...order};
			if(!ord.concilia) ord.concilia = [];
			ord.concilia.push({
				deposit_id: res.data.deposit_id,
				payment_id: depositForm.payment_id,
				admin_id: user.admin_id,
				amount: parseFloat(depositForm.amount.toString()) * (depositForm.type===OrderDepositType.DEPOSIT ? 1 : -1),
				external_id: depositForm.external_id,
				cancelled: false,
				date_cancelled: null,
				date_created: moment().unix(),
				date_deposit: depositForm.date_deposit,
				deposit_type: depositForm.type,
				deposit_type_name: depositForm.type===OrderDepositType.DEPOSIT ? 'Deposito' : (depositForm.type===OrderDepositType.REFUND ? 'Reembolso' : 'Contracargo'),
				payment_method: depositForm.payment_method,
				payment_method_name: depositForm.payment_method_name,
			});
			setOrder(ord);
			setShownModal(null);
			Toast.success('Se ha agregado la conciliación.');
		}).catch(err=>{
			return setModalPrompts(['Hubo un error inesperado creando la conciliación (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var showRefundForm = ()=>{
		setRefundForm({
			commission: false,
			delivery: false,
			email: order.email,
			payment: false,
			send_email: true,
			fake: false,
		});
		showModal(MODAL.ORDER_REFUND)();
	}

	var createRefund = (setLoading: SetLoading)=>{
		if(!user_access.refund) return;
		if((refundForm.commission || refundForm.delivery || refundForm.payment) && !user_access.refund_complete) return;
		if(refundForm.fake && !user_access.refund_fake) return;

		if(!order.email || order.email.length<5){
			if(!Validator.validate([{ rule: 'email' }], refundForm.email)){
				return setModalPrompts(['El correo electrónico no es válido.']);
			}
		}

		setLoading(true);
		API.createRefund(order.order_id, ticketsSelected, {
			commission: refundForm.commission,
			delivery: refundForm.delivery,
			email: refundForm.email,
			payment: refundForm.payment,
			completed: refundForm.fake,
			send_email: refundForm.send_email,
		}).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			if(!res.data || !res.data?.refund) return setModalPrompts(['Hubo un error inesperado creando el reembolso (LCL-2)']);
			var refunds = order.refunds ? [...order.refunds] : [];
			for(var i of refunds){
				if(!i.executed) i.cancelled = true;
			}

			var payments = order.payments ? [...order.payments] : [];
			if(res.data.payments){
				for(var p of res.data.payments){
					var px = payments.findIndex(a=>a.payment_id==p.payment_id);
					if(px==-1) continue;
					payments[px].total_refunded += p.amount;
				}
			}

			var tickets = order.tickets ? [...order.tickets] : [];
			if(res.data.tickets){
				for(var t of res.data.tickets){
					var tx = tickets.findIndex(a=>a.ticket_id==t.ticket_id);
					if(tx===-1) continue;
					tickets[tx].cancelled = true;
					tickets[tx].refunded = 1;
					tickets[tx].amount_refunded = t.amount_refunded;
					tickets[tx].commission_refunded = t.commission_refunded;
					tickets[tx].cancel_reason = CancelReason.REFUNDED;
				}
			}

			setOrder({
				...order,
				payment_cost: order.payment_cost-(!refundForm.fake ? 0 : res.data.refund.payment_cost),
				delivery_cost: order.delivery_cost-(!refundForm.fake ? 0 : res.data.refund.delivery_cost),
				tickets,
				payments,
				refunds: [
					...refunds,
					res.data.refund,
				]
			});
			showModal(null)();
			if(refundForm.fake){
				Toast.success('Se ha marcado los boletos como reembolsados.')
			}else{
				Toast.success('Se le ha enviado a el cliente la solicitud de reembolso.')
			}
		}).catch(err=>{
			return setModalPrompts(['Hubo un error inesperado creando el reembolso (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		});
	}

	var onOpenPaymentModal = (payment_id: number) => {
		setPaymentIdSelected(payment_id)
		setShownModal(MODAL.PAYMENT_INFO);
	}

	var onHideTicket = (setLoading: SetLoading) => {
		setLoading(true);
		API.setTicketHidden(ticket.ticket_id, !ticket.hidden).then(res => {
			if (res.data) {
				Toast.success(`Se escondido el boleto correctamente`);
				order.tickets = order.tickets.map(t => {
					if (t.ticket_id === ticket.ticket_id) {
						t.hidden = !t.hidden;
					}
					return t;
				});
				setOrder(order)
				setShownModal(null);
			}
		}).catch(err => {
			Toast.error('Hubo un error inesperado (LCL-1)');
		}).finally(()=>{
			setLoading(false);
		});
	}

	var onPaymentChange = (paymentInfo: { payment_id: number, payment_cost: number, delivery_cost: number, total: number }) => {
		var pays = [...order.payments];
		var ix = pays.findIndex(a=>a.payment_id===paymentInfo.payment_id);
		if(ix===-1) return;
		pays[ix].payment_cost = paymentInfo.payment_cost;
		pays[ix].delivery_cost = paymentInfo.delivery_cost;
		pays[ix].total = paymentInfo.total;
		pays[ix].total_available = paymentInfo.total-pays[ix].total_refunded;

		setOrder({
			...order,
			payments: pays
		});
	}

	var onLocationChange = (location: Location) => {
		if (location.location_id !== order.delivery_location) {
			API.updateOrderLocation(order.order_id, location.location_id).then(res => {
				if (res.error) return Toast.error('Ocurrio un error actualizando la direccion');
				setOrder({
					...order,
					delivery_location: location.location_id
				});
				return Toast.success('Se actualizo la direccion de la orden correctamente');
			}).catch(err => {
				Toast.error('Hubo un error inesperado (LCL-1)');
			});
		}
	}

	var user_access = {
		tickets: hasAccess(UserAccess.ORDERS.VIEW_TICKETS),
		pdf: hasAccess(UserAccess.ORDERS.VIEW_PDF),
		emails: hasAccess(UserAccess.ORDERS.VIEW_ORDER_EMAILS),
		payments: hasAccess(UserAccess.ORDERS.VIEW_PAYMENTS),
		extend: hasAccess(UserAccess.ORDERS.EXTEND_EXPIRATION),
		revive: hasAccess(UserAccess.ORDERS.REVIVIE_TICKET),
		concilia: hasAccess(UserAccess.CONTABILIDAD.VIEW_DEPOSITS),
		view_refund: hasAccess(UserAccess.ORDERS.VIEW_REFUND),
		refund_request: hasAccess(UserAccess.ORDERS.CREATE_REFUND_REQUEST),
		refund: hasAccess(UserAccess.ORDERS.CREATE_REFUND),
		refund_complete: hasAccess(UserAccess.ORDERS.REFUND_COMPLETE),
		refund_fake: hasAccess(UserAccess.ORDERS.CREATE_FAKE_REFUND),
	}
	var total_tickets = 0, total_commission = 0, total_refunded = 0;
	if(order.tickets){
		if(order.tickets){
			for(var i of order.tickets){
				if(i.cancelled && (i.cancel_reason!==CancelReason.REFUNDED|| !i.amount_refunded)) continue;
				total_tickets += i.ticket_cost-(i.amount_refunded-i.commission_refunded);
				total_commission += i.commission-(i.commission_refunded || 0);
			}
		}
	}
	var active_payments = order.payments && order.payments.filter(a=>a.paid && !a.cancelled);
	var payments_total = 0, refunded_total = 0, total_final = 0, total_paid = 0;
	for(var p of active_payments){
		payments_total += typeof p.total_available !== 'undefined' ? p.total_available : p.total;
		refunded_total += typeof p.total_available !== 'undefined' ? p.total_refunded : 0;
		total_refunded += p.total_refunded;
		total_paid += p.total;
	}
	total_final = total_tickets+total_commission+order.payment_cost+order.delivery_cost;

	var total_concilia_deposits = 0, total_concilia_refunds = 0;
	if(order.concilia){
		for(var c of order.concilia){
			if(c.amount>0){
				total_concilia_deposits += c.amount;
			}else{
				total_concilia_refunds += Math.abs(c.amount);
			}
		}
	}

	var selected_concilia = selectedDeposit && order.concilia.find(a=>selectedDeposit===a.deposit_id);
	var refundingTickets = order && order.tickets && shownModal===MODAL.ORDER_REFUND && ticketsSelected && ticketsSelected.length>0 ? order.tickets.filter(a=>ticketsSelected.indexOf(a.ticket_id)>-1) : [];
	var refund_tickets_amount = refundingTickets.reduce((a,b)=>a+b.ticket_cost, 0);
	var refund_commission_amount = refundingTickets.reduce((a,b)=>a+b.commission, 0);
	var refund_total = refund_tickets_amount+(refundForm?.commission ? refund_commission_amount : 0)+(refundForm?.delivery ? order.delivery_cost : 0)+(refundForm?.payment ? order.payment_cost : 0);
	var pending_refunds = order.refunds.filter(a=>!a.executed && !a.cancelled);

	var onOrderEditChange = bindFormChange(orderEdit, setOrderEdit);
	var onTicketEditChange = bindFormChange(ticket, setTicket);
	var onMoveOrderChange = bindFormChange(moveOrder, setMoveOrder);
	var onDepositFormChange = bindFormChange(depositForm, setDepositForm);
	var onRefundFormChange = bindFormChange(refundForm, setRefundForm);

	return <div className="ar order-view-screen">
		<Header text={`Orden ${order.order_hash}`} />
		{!!order.cancelled && (
			<Message header="Orden cancelada" type="error" centered style={{ width: 400, margin: 'auto', marginBottom: 10 }} />
		)}
		{!order.cancelled && user_access.tickets && (!order.tickets || order.tickets.length==0) && (
			<Message header="Orden sin boletos" type="info" centered style={{ width: 400, margin: 'auto', marginBottom: 10 }} />
		)}
		{!order.cancelled && !!order.expired && (
			<Message header="Orden expirada" centered style={{ width: 400, margin: 'auto', marginBottom: 15 }}>
				{user_access.revive && <Button text="Revivir" color="black" onClick={showModal(MODAL.EXTEND)} style={{ marginTop: 5, width: 200 }} />}
			</Message>
		)}
		{(total_tickets+total_commission+order.delivery_cost+order.payment_cost)!=payments_total && !!order.paid ? (
			<Message header="Atención" type="info" style={{ width: 400, margin: 'auto', marginBottom: 10 }}>
				Los totales de la orden y lo pagado esta desfasado, favor de contactar desarrollo.
				<ul>
					<li>Total en orden: {addCommas(total_tickets+total_commission+order.payment_cost+order.delivery_cost)}</li>
					<li>Total en pagos: {addCommas(payments_total)}</li>
					<li>Diferencia: {addCommas(Math.abs((total_tickets+total_commission+order.payment_cost+order.delivery_cost)-payments_total))}</li>
				</ul>
			</Message>
		) : null}
		{pending_refunds.length>0 && (
			<Message header="Reembolso pendiente" centered style={{ width: 400, margin: 'auto', marginBottom: 10 }}>
				Esta orden tiene un reembolso pendiente de ser completada por el cliente. Se le envió a el cliente un correo electrónico con instrucciones de como completar su reembolso.
				<Button text="Ver reembolso" color="black" style={{ margin: 'auto', marginTop: 10, display: 'block', width: 200 }} as={Link} to={`/refunds/${pending_refunds[0].refund_id}`} />
			</Message>
		)}
		{!order.expired ? (
			<Toolbar stretch divided className="order" items={[
				{ text: 'Marcar pagado', 	icon: 'check-circle', 		onClick: showModal(MODAL.SET_PAID), 		if: (!order.cancelled && !order.paid) },
				{ text: 'Reenviar', 			icon: 'envelope', 			onClick: showModal(MODAL.RESEND), 			if: !order.cancelled },
				{ text: 'Editar', 			icon: 'pen',					items: [
					{ text: 'Cambiar datos', 	 		onClick: showOrderEdit, 		if: hasAccess(UserAccess.ORDERS.CHANGE_ORDER_DATA) },
					{ text: 'Cambiar totales',  		onClick: showTotalsEdit, 		if: hasAccess(UserAccess.ORDERS.CHANGE_TOTALS) },
					{ text: 'Cambiar entrega',  		onClick: showDeliveryChange, 	if: hasAccess(UserAccess.ORDERS.CHANGE_DELIVERY_METHOD) },
				]},
				{ text: 'Reembolsar', 		icon: 'hand-holding-usd', 	onClick: showRefundForm,	if: !order.cancelled && !!order.paid && (user_access.refund || user_access.refund_request) },
				{ text: 'Herramientas',		icon: 'wrench',				items: [
					{ text: 'Ver PDF', 					onClick: viewOrderPdf,							if: hasAccess(UserAccess.ORDERS.VIEW_PDF) },
					{ text: 'Ver facturas', 			onClick: showModal(MODAL.ORDER_INVOICES),	if: hasAccess(UserAccess.ORDERS.VIEW_ORDERS) },
					{ text: 'Ver correos',				onClick: showModal(MODAL.ORDER_EMAILS), 	if: hasAccess(UserAccess.ORDERS.VIEW_ORDER_EMAILS) },
					{ text: 'Convertir a cortesía',  onClick: showModal(MODAL.CONVERT_FREE), 	if: hasAccess(UserAccess.ORDERS.CONVERT_FREE) },
					{ text: 'Conciliar', 				onClick: showCreateDeposit,					if: hasAccess(UserAccess.ORDERS.MANUAL_DEPOSIT) },
					{ text: 'Mover boletos', 			onClick: showModal(MODAL.MOVE_TICKETS), 	if: order.tickets && (order.tickets.reduce((a,b)=>a+(!b.cancelled ? 1 : 0), 0)>0) && hasAccess(UserAccess.ORDERS.MOVE_TICKETS) },
					// { text: 'Ver recibo', if: hasAccess(UserAccess.ORDERS.VIEW_RECEIPT) },
					{ text: 'Revivir boletos', 		onClick: showModal(MODAL.REVIVE_TICKETS), if: order.tickets && (order.tickets.reduce((a,b)=>a+(b.cancelled ? 1 : 0), 0)>0) && hasAccess(UserAccess.ORDERS.REVIVIE_TICKET), className: 'green' },
					{ text: 'Expirar orden',  			onClick: showModal(MODAL.EXPIRE_ORDER), 	if: !order.cancelled && !order.paid && !order.expired && hasAccess(UserAccess.ORDERS.CANCEL_ORDER), className: 'red'},
					{ text: 'Cancelar orden',			onClick: showModal(MODAL.CANCEL_ORDER), 	if: !order.cancelled && hasAccess(UserAccess.ORDERS.CANCEL_ORDER), className: 'red', }
				]}
			]} />
		) : null}
		<Groupper title="Detalles de orden" fitted style={{ marginTop: 15 }} width={600}>
			<Table details striped>
				<Table.Row data={['Identificador', <>
					<span className="fr mono">{order.order_hash}</span>
					<div className="meta">
						<span style={{ color: 'transparent' }}>:</span>
						ID: {order.order_id}
					</div>
				</>]} />
				<Table.Row data={['Estatus', <>
					<div className={`fr label ${order.cancelled ? 'red' : (order.expired ? '' : (order.paid ? 'green' : 'blue'))}`}>
						{order.cancelled ? 'Cancelado' : (order.expired ? 'Expirado' : (order.paid ? 'Pagado' : 'Pendiente'))}
					</div>
					{order.refunds && order.refunds.filter(a=>!!a.executed).length>0 && (
						<div className="fr red label" style={{ marginLeft: 5 }}>Reembolso</div>	
					)}
				</>]} />
				{!!order.pdv_id && <Table.Row data={['PDV', <>
					[{order.pdv_id}] {order.pdv_name}
					<div className="meta">
						<Link className="fr tiny button" to={`/pdv/${order.pdv_id}`}>Ver PDV</Link>
					</div>
				</>]} />}
				{!!order.admin_id && <Table.Row data={['Vendedor', <>
					[{order.admin_id}] {order.creator_username}
					<div className="meta">
						<Link className="fr tiny button" to={`/admins/${order.admin_id}`}>Ver usuario</Link>
					</div>
				</>]} />}
				<Table.Row data={['Nombre', order.first_name || order.last_name ? `${order.first_name} ${order.last_name}` : <i>Sin nombre</i>]} />
				<Table.Row data={['Correo', order.email || <i>Sin correo</i>]} />
				<Table.Row data={['Creación', moment.unix(order.date_created).format('DD/MMM/YY HH:mm:ss')]} />
				{!order.paid && !!order.date_expiration && (
					<Table.Row data={['Expiración', <>
						{moment.unix(order.date_expiration).format('DD/MMM/YY HH:mm:ss')}
						<div className="meta">{moment.unix(order.date_expiration).fromNow(false)}</div>
					</>]} />
				)}
				{!!order.paid && (
					<Table.Row data={['Pagado', moment.unix(order.date_paid).format('DD/MMM/YY HH:mm:ss')]} />
				)}
				<Table.Row data={['Forma de pago', (
					active_payments && active_payments.length>0 ? <>
						{uniqueArray(active_payments.map(a=>a.method_name_internal)).join(', ')}
						<div className="meta">
							ID: {uniqueArray(active_payments.map(a=>a.payment_method)).join(', ')}
						</div>
					</> : order.payment_method ? <>
						{order.payment_method_name_internal}
						<div className="meta">
							ID: {order.payment_method}
						</div>
					</>: (
						<i>Sin forma de pago</i>
					)
				)]} />
				<Table.Row data={['Entrega', (
					order.delivery_id ? <>
						{order.delivery_name}
						<div className="meta">
							ID: {order.delivery_id}
						</div>
					</> : <i>Sin forma de entrega</i>
				)]} />
				{!!order.delivery_needs_location && (
					<Table.Row data={['Dirección', (
						order.delivery_location ? (
							<Button size="tiny" text="Ver dirección" onClick={showModal(MODAL.ORDER_LOCATION)} />
						) : <>
							<span style={{ color: 'brown' }}><i>Sin dirección de entrega</i></span>
							<div className="meta">
								<Button text="Agregar dirección" size="tiny" onClick={showModal(MODAL.ORDER_LOCATION)} />
							</div>
						</>
					)]} />
				)}
			</Table>
			<Table 
				className="totals"
				headers={['Boletos', 'Comisión', 'Forma pago', 'Entrega', 'Total']}
				data={[[
					addCommas(total_tickets),
					addCommas(total_commission),
					addCommas(order.payment_cost),
					addCommas(order.delivery_cost),
					<b>{addCommas(total_final)}</b>
				]]}
			/>
		</Groupper>
		{user_access.view_refund && order.refunds && order.refunds.length>0 && (
			<Table
				title="Reembolsos"
				selectable
				style={{ margin: 'auto', marginTop: 15, maxWidth: 600 }}
				collapsingIndexes={[0, 2, 3]}
				headers={['ID', 'Fecha', 'Estatus', 'Cantidad']}
			>
				{order.refunds.map(a=>(
					<Table.Row compact as={Link} to={`/refunds/${a.refund_id}`} data={[
						a.refund_id,
						moment.unix(a.date_completed || a.date_created).format('DD/MMM/YY HH:mm'),
						<div className={classNames('fr label', {
							green: !a.cancelled && a.completed,
							yellow: !a.cancelled && !a.completed && a.executed,
							blue: !a.cancelled && !a.executed && !a.completed,
							red: a.cancelled,
						})}>
							{
								a.cancelled ? 'Cancelado' : 
								(a.completed ? 'Completado' : 
								(a.executed ? 'Pendiente' : 'Enviado'))
							}
						</div>,
						addCommas(a.total)
					]} />
				))}
			</Table>
		)}
		{user_access.tickets && order.tickets && order.tickets.length>0 && (
			<Groupper className="tickets" fitted width={800} title="Boletos" style={{ marginTop: 15 }}>
				{groupBy(order.tickets, 'date_id', ['date', 'event_name', 'event_id']).map(a=><div key={`tkt-ev-${a.event_id}`}>
					<Table details selectable>
						<Table.Row as={Link} compact to={`/events/${a.event_id}`} data={['Evento', <>
							{a.event_name}
							<div className="meta">ID: {a.event_id}</div>
						</>]} />
						<Table.Row as={Link} compact to={`/events/${a.event_id}/dates/${a.date_id}`} data={['Calendario', <>
							{moment.unix(a.date).format('DD/MMM/YY HH:mm')}
							<div className="meta">ID: {a.date_id}</div>
						</>]} />
						<Table.Row selectable={false} data={['Total Evento', addCommas(a.values.reduce((a: number, b: Ticket)=>a+b.ticket_cost+b.commission, 0))]} />
					</Table>
					<Table
						className="tickets"
						collapsingIndexes={[0, 3, 4, 5, 6, 7, 8]}
						centeredIndexes={[3, 5, 6, 7]}
						headers={['ID', 'Sección', 'Producto', 'Butaca', 'Estado', 'Usos', 'Precio', '']}
						data={a.values.map((t: Ticket)=>([
							<>
								<div className="fr mono">{t.ticket_hash}</div>
								<div style={{ color: 'gray', fontSize: 12 }}>
									{dev ? `ID: ${t.ticket_id} ` : null}
									{t.seat_id ? <div>
										<i className="chair icon"></i>
										<span className="fr mono">{t.seat_id}</span>
									</div> : null}
								</div>
							</>,
							t.section_name,
							<>
								{t.ticket_date ? <div>
									{moment.unix(t.ticket_date).format('DD/MMM/YY HH:mm')}
								</div> : null}
								{t.price_name}
							</>,
							t.seat_id ? <>
								<div style={{ fontSize: 20, fontWeight: 'bold' }}>
									{formatSeatNumber(t.seat_row, t.seat_number)}
								</div>
								{t.seat_section ? (
									<div style={{ fontSize: 12 }}>{t.seat_section}</div>
								) : null}
							</> : <Icon color="grey" name="minus" />,
							<div className={`fr label ${t.cancelled ? 'red' : (order.expired ? 'transparent' : (t.paid ? 'green' : 'blue'))}`}>
								{t.hidden ? <Icon name="eye slash" /> : null}
								{t.cancelled ? (i.cancel_reason===CancelReason.REFUNDED ? 'Reembolsado' : 'Cancelado') : (order.expired ? 'Expirado' : (t.paid ? 'Pagado' : 'Pendiente'))}
							</div>,
							t.uses>0 ? (
								<div>
									<Icon name="check" color="green" />
									{t.uses}
								</div>
							) : (
								<Icon name="remove" color="grey" />
							),
							addCommas(t.ticket_cost+t.commission),
							<Button size="tiny" iconName="cog" style={{ minWidth: 16 }} onClick={showTicketOptions(t)} />
						]))}
					/>
				</div>)}
			</Groupper>
		)}
		{user_access.payments && (
			<Table 
				title="Pagos" 
				style={{ margin: 'auto', marginTop: 15, maxWidth: 800 }}
				selectable
				emptyText="Esta orden no tiene pagos."
				collapsingIndexes={[0, 2, 3, 4, 5, 6, 7]}
				centeredIndexes={[0, 2, 3, 4, 5, 6, 7]}
				headers={['ID', 'Método', 'Estatus', 'Fecha', 'Comisión', ...(refunded_total>0 ? ['Cantidad', 'Reembolso'] : []), 'Total']}
				onClick={(v,i) => onOpenPaymentModal(v[0])}
				data={order.payments && order.payments.length>0 ? order.payments.map(a=>([
					a.payment_id,
					<>
						{dev ? `[${a.payment_method}] ` : null}
						{a.method_name_internal}
					</>,
					<div className={`fr label ${a.cancelled ? 'red' : (a.paid ? 'green' : 'transparent')}`}>
						{a.cancelled ? 'Cancelado' : (a.paid ? 'Pagado' : 'Pendiente')}
					</div>,
					moment.unix(a.date_paid || a.date_created).format('DD/MM/YY HH:mm'),
					addCommas(a.payment_cost),
					...(refunded_total>0 ? [
						addCommas(a.total),
						addCommas(a.total_refunded),
					] : []),
					addCommas(a.total_available),
				])) : []}
			/>
		)}
		{!!user_access.concilia && payments_total>0 && (
			<Table title="Conciliación" style={{ margin: 'auto', marginTop: 15, maxWidth: 700 }}>
				<Table.Row key={'dep-1'} details>
					<Table.Cell key={'dep-1-1'} colSpan={2}>Depositos</Table.Cell>
					<Table.Cell key={'dep-1-2'} colSpan={5} className={classNames({
						red: total_paid<total_concilia_deposits
					})}>
						{addCommas(total_concilia_deposits)} / {addCommas(total_paid)}
						<div className="meta">
							{Math.round((total_concilia_deposits*100)/total_paid)}%
						</div>
					</Table.Cell>
				</Table.Row>
				{(total_concilia_refunds>0 || refunded_total>0) && (
					<Table.Row key={'dep-2'} details>
						<Table.Cell key={'dep-2-1'} colSpan={2}>Reembolsos</Table.Cell>
						<Table.Cell key={'dep-2-2'} colSpan={5} className={classNames({
							red: refunded_total<total_concilia_refunds
						})}>
							{addCommas(total_concilia_refunds)} / {addCommas(refunded_total)}
							<div className="meta">
								{Math.round((total_concilia_refunds*100)/refunded_total)}%
							</div>
						</Table.Cell>
					</Table.Row>
				)}
				{order.concilia && order.concilia.length>0 ? <>
					<Table.Row header data={['ID','Tipo','Método','Fecha','Cantidad','']} />
					{order.concilia.map(a=>(
						<Table.Row collapsingIndexes={[0, 1, 3, 4, 5]} key={`conc-${a.deposit_id}`} data={[
							a.deposit_id, a.deposit_type_name, a.payment_method_name, moment.unix(a.date_deposit).format('DD/MMM/YY'), addCommas(a.amount),
							<Dropdown
								icon={null}
								trigger={(
									<Button icon iconName="ellipsis-h" size="tiny" />
								)}
							>
								<Dropdown.Menu>
									<Dropdown.Item onClick={showDeleteDeposit(a.deposit_id)}>Eliminar</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						]} />
					))}
				</> : (
					<Table.Cell colSpan={6} row empty>Esta orden no tiene conciliación</Table.Cell>
				)}
			</Table>
		)}
		<Modal open={shownModal==MODAL.RESEND} onClose={bindClose(setShownModal)} size="mini">
			<Modal.Header>Reenviar boletos</Modal.Header>
			<Modal.Content>
				<Header text="¿Reenviar el correo al cliente?" subtext={`Se le reenviará el correo ${order.paid ? 'con los boletos' : 'de las instrucciónes de pago'} a el cliente.`} />
				<Input label="Correo" value={order.email} readonly />
			</Modal.Content>
			<Modal.Actions>
				<Button text="Cerrar" basic onClick={bindClose(setShownModal)} />
				<Button text="Reenviar" color="black" onClick={resend} />
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal==MODAL.SET_PAID} onClose={bindClose(setShownModal)} size="tiny">
			<Modal.Header>Marcar pagada</Modal.Header>
			<Modal.Content>
				<Header text="Marcar orden pagada" subtext={'Se marcará la orden y los boletos pendientes de pago como pagados.'} />
				<Message type="info" text="No se le mandará correo al cliente" centered />
				<Message style={{ marginTop: 15 }} list={modalPrompts} type="error" />
			</Modal.Content>
			<Modal.Actions>
				<Button text="Cerrar" basic onClick={bindClose(setShownModal)} />
				<Button text="Marcar pagada" color="black" onClick={setPaid} />
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal==MODAL.EXTEND} onClose={bindClose(setShownModal)} size="tiny">
			<Modal.Header>{order.expired ? 'Revivir' : 'Extender'} Orden</Modal.Header>
			<Modal.Content>
				<Header text={`${order.expired ? 'Revivir' : 'Extender'} orden`} subtext={'Se le agregará tiempo a la orden para realizar el pago.'} />
				{order.expired ? (
					<Message type="info" text="Como esta orden ya se expiró, se tendrá que revisar si los boletos están disponibles para ser revividos." centered style={{ marginBottom: 10 }} />
				) : null}
				<Field label="Tiempo a agregar" comment={order.expired ? 'Tiempo a agregar para realizar el pago una vez se haya revivido la orden.' : null}>
					<Dropdown placeholder="Tiempo a agregar" value={extendAmount} onChange={bindSemantic(setExtendAmount)} selection options={[5, 10, 30, 60, 120, 1440, 2880].map(a=>({
						value: a*60,
						text: a>=60 ? `${Math.floor(a/60)} hora${Math.floor(a/60)==1 ? '' : 's'}` : `${a} minutos`
					}))} />
				</Field>
				<Message style={{ marginTop: 15 }} list={modalPrompts} type="error" />
			</Modal.Content>
			<Modal.Actions>
				<Button text="Cerrar" basic onClick={bindClose(setShownModal)} />
				<Button text={order.expired ? 'Revivir' : 'Extender'} color="black" onClick={sendExtend} />
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal===MODAL.VIEW_PDF} onClose={bindClose(setShownModal)} size="mini">
			<Modal.Header>Ver PDF</Modal.Header>
			<Modal.Content>
				{orderPdf ? (
					<div>
						<Header size="small" text={`PDF ${order.order_hash}`} subtext="En seguida se muestra el enlace para ver el pdf de los boletos." />
						<Input readonly value={orderPdf} onFocus={(e)=>e.target.select()} button={(
							<Button icon iconName="external-link" onClick={()=>window.open(orderPdf, '_blank')} />
						)} />
					</div>
				) : (
					<Header loading text="Cargando pdf..." />
				)}
			</Modal.Content>
			<Modal.Actions>
				<Button text="Cerrar" onClick={bindClose(setShownModal)} />
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal===MODAL.ORDER_EDIT} onClose={bindClose(setShownModal)} size="tiny">
			<Modal.Header>Editar orden {order.order_hash}</Modal.Header>
			{orderEdit && <Modal.Content>
				<Field amount={2}>
					<Input label="Nombre" value={orderEdit.first_name} onChange={onOrderEditChange('first_name')} />
					<Input label="Apellido" value={orderEdit.last_name} onChange={onOrderEditChange('last_name')} />
				</Field>
				{hasAccess(3201) && (
					<Input label="Correo electrónico" value={orderEdit.email} onChange={onOrderEditChange('email')} />
				)}
				<Message type="error" list={modalPrompts} />
			</Modal.Content>}
			<Modal.Actions>
				<Button text="Cerrar" basic onClick={bindClose(setShownModal)} />
				<Button text="Guardar" color="black" onClick={sendOrderEdit} />
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal==MODAL.ORDER_TOTALS} onClose={bindClose(setShownModal)} size="mini">
			<Modal.Header>Editar costos {order.order_hash}</Modal.Header>
			{orderEdit && <Modal.Content>
				<Input label="Boletos" readonly value={total_tickets} />
				<Field amount={2}>
					<Input label="Comisión" readonly value={total_commission} />
					<Input label="Forma de pago" readonly value={orderEdit.payment_cost} />
				</Field>
				<Input label="Entrega" value={orderEdit.delivery_cost} onChange={onOrderEditChange('delivery_cost')} />
				<Stat label="Total" value={addCommas(total_tickets+total_commission+(parseFloat(orderEdit.payment_cost.toString()) || 0)+(parseFloat(orderEdit.delivery_cost.toString()) || 0))} style={{ paddingBottom: 0 }} />
				<Message type="error" list={modalPrompts} />
			</Modal.Content>}
			<Modal.Actions>
				<Button text="Cerrar" basic onClick={bindClose(setShownModal)} />
				<Button text="Guardar" color="black" onClick={sendTotalsEdit} />
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal==MODAL.ORDER_PAYMENT_EDIT} onClose={bindClose(setShownModal)} size="mini">
			<Modal.Header>Editar forma de pago</Modal.Header>
			{orderEdit && <Modal.Content>
				{paymentMethods ? (
					<Field label="Forma de pago">
						<Dropdown selection placeholder="Selecciona la forma de pago" value={orderEdit.payment_method} onChange={onOrderEditChange('payment_method', true)} options={paymentMethods.map(a=>({
							value: a.method_id,
							text: a.method_name_internal,
							description: `ID: ${a.method_id}`,
						}))} />
					</Field>
				) : (
					<Header loading text="Cargando formas de pago..." />
				)}
				<Message type="error" list={modalPrompts} />
			</Modal.Content>}
			<Modal.Actions>
				<Button text="Cerrar" basic onClick={bindClose(setShownModal)} />
				<Button text="Guardar" color="black" onClick={sendPaymentChange} />
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal==MODAL.ORDER_DELIVERY_EDIT} onClose={bindClose(setShownModal)} size="mini">
			<Modal.Header>Editar entrega</Modal.Header>
			{orderEdit && <Modal.Content>
				{deliveryMethods ? (
					<Field label="Forma de entrega">
						<Dropdown selection placeholder="Selecciona la forma de entrega" value={orderEdit.delivery_id} onChange={onOrderEditChange('delivery_id', true)} options={deliveryMethods.map(a=>({
							value: a.delivery_id,
							text: a.method_name,
							description: `ID: ${a.delivery_id}`,
						}))} />
					</Field>
				) : (
					<Header loading text="Cargando formas de entrega..." />
				)}
				<Message type="error" list={modalPrompts} />
			</Modal.Content>}
			<Modal.Actions>
				<Button text="Cerrar" basic onClick={bindClose(setShownModal)} />
				<Button text="Guardar" color="black" onClick={sendDeliveryChange} />
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal===MODAL.CONVERT_FREE} onClose={bindClose(setShownModal)} size="mini">
			<Modal.Header>Convertir en cortesía</Modal.Header>
			<Modal.Content>
				<Header size="small" text="Convertir en cortesía" subtext="Esto podrá el costo de todos los boletos, cargos por servicio, cargos de entrega y pago en 0." />
				<Checkbox toggle label="Esta acción es la que deseo realizar." checked={confirmCheck} onChange={bindSemantic(setConfirmCheck)} />
			</Modal.Content>
			<Modal.Actions>
				<Button text="Cerrar" basic onClick={bindClose(setShownModal)} />
				<Button text="Convertir" color="red" onClick={sendConvertFree} disabled={!confirmCheck} />
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal===MODAL.MOVE_TICKETS} onClose={bindClose(setShownModal)} size="tiny">
			<Modal.Header>Mover boletos</Modal.Header>
			<Modal.Content>
				<Header size="small" text="Mover boletos" subtext="Mover los boletos a otra orden." style={{ marginTop: 0 }} />
				{order.tickets && order.tickets.length>0 ? (
					<Items selectable striped toggle
						style={{ marginTop: 15 }}
						data={order.tickets.filter(a=>!a.cancelled)}
						valueExtractor={a=>a.ticket_id}
						selected={ticketsSelected}
						onToggle={setTicketsSelected}
						render={(a)=><TicketItem ticket={a} />} 
					/>
				) : null}
				<Input label="Orden" comment="Orden en donde terminarán los boletos" style={{ marginTop: 15 }} value={moveOrder?.order_hash || ''} onChange={onMoveOrderChange('order_hash')} />
				{moveOrder?.show_force && (
					<Checkbox label="Mover boletos a orden cancelada y revivir orden" checked={moveOrder?.force || false} onChange={onMoveOrderChange('force', true)} />
				)}
				<Message type="error" list={modalPrompts} style={{ marginTop: 15 }} />
			</Modal.Content>
			<Modal.Actions>
				<Button text="Cerrar" basic onClick={bindClose(setShownModal)} />
				<Button text="Mover" color="black" onClick={sendMoveTickets} />
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal===MODAL.EXPIRE_ORDER} onClose={bindClose(setShownModal)} size="mini">
			<Modal.Header>Expirar orden</Modal.Header>
			<Modal.Content>
				<Header size="small" text="Expirar orden" subtext="Esto podrá el tiempo restante para realizar el pago en 0" />
				{!!order.paid && (
					<Message header="¡Atención" text="Esta orden ya fue pagada, favor de asegurarse de que esta es la acción que se desea realizar." type="error" centered style={{ marginTop: 15, marginBottom: 15 }} />
				)}
				<Checkbox toggle label="Esta acción es la que deseo realizar." checked={confirmCheck} onChange={bindSemantic(setConfirmCheck)} />
			</Modal.Content>
			<Modal.Actions>
				<Button text="Cerrar" basic onClick={bindClose(setShownModal)} />
				<Button text="Expirar orden" color="black" onClick={sendExpireOrder} disabled={!confirmCheck} />
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal===MODAL.CANCEL_ORDER} onClose={bindClose(setShownModal)} size="mini">
			<Modal.Header>Cancelar orden</Modal.Header>
			<Modal.Content>
				<Header size="small" text="Cancelar orden" subtext="Esto cancelará la orden, sus boletos, sus pagos y liberará las butacas." />
				{!!order.paid && (
					<Message header="¡Atención" text="Esta orden ya fue pagada, favor de asegurarse de que esta es la acción que se desea realizar." type="error" centered style={{ marginTop: 15, marginBottom: 15 }} />
				)}
				<Checkbox toggle label="Esta acción es la que deseo realizar." checked={confirmCheck} onChange={bindSemantic(setConfirmCheck)} />
			</Modal.Content>
			<Modal.Actions>
				<Button text="Cerrar" basic onClick={bindClose(setShownModal)} />
				<Button text="Cancelar orden" color="red" onClick={sendCancelOrder} disabled={!confirmCheck} />
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal===MODAL.REVIVE_TICKETS} onClose={bindClose(setShownModal)} size="tiny">
			<Modal.Header>Revivir boletos</Modal.Header>
			<Modal.Content>
				<Header size="small" text="Revivir boletos" subtext="El sistema revisará si el boleto puede ser revivido (cupo, butaca libre) y marcará el boleto como no cancelado. Si el boleto no ha sido pagado no se marcará como pagado." style={{ marginTop: 0 }} />
				{order.tickets && order.tickets.length>0 ? (
					<Items selectable striped toggle
						style={{ marginTop: 15 }}
						data={order.tickets.filter(a=>!!a.cancelled)}
						valueExtractor={a=>a.ticket_id}
						selected={ticketsSelected}
						onToggle={setTicketsSelected}
						render={(a)=><TicketItem ticket={a} />} 
					/>
				) : null}
				<Message type="error" list={modalPrompts} style={{ marginTop: 15 }} />
			</Modal.Content>
			<Modal.Actions>
				<Button text="Cerrar" basic onClick={bindClose(setShownModal)} />
				<Button text="Revivir boletos" color="black" onClick={sendReviveTickets} disabled={ticketsSelected.length==0} />
			</Modal.Actions>
		</Modal>
		<Modal open={!!ticket && shownModal==MODAL.TICKET_OPTIONS} onClose={bindClose(setShownModal)} size="mini">
			<Modal.Header>Boleto {ticket?.ticket_hash}</Modal.Header>
			<Modal.Content scrolling>
				<div className="fr selectable striped divided items">
					<ToolbarItem text="Ver detalles" icon="ticket" onClick={showModal(MODAL.TICKET_DETAILS)} />
					{hasAccess(3006) && <ToolbarItem text="Ver formulario" icon="clipboard list" onClick={showTicketForm} />}
					{hasAccess(3004) && <ToolbarItem text="Ver usos" icon="qrcode" onClick={showTicketUses} />}
					{hasAccess(20002) && <ToolbarItem text="Usar boleto" icon="check square" onClick={showUseTicket} />}
					{hasAccess(3206) && <ToolbarItem text="Editar precio" icon="dollar sign" onClick={showModal(MODAL.TICKET_EDIT)} />}
					{!!ticket?.seat_id && hasAccess(UserAccess.ORDERS.CHANGE_TICKET_SEAT) && (
						<ToolbarItem text="Re-Asignar Butaca" icon="exchange" onClick={showModal(MODAL.TICKET_UPDATE_SEAT)} />
					)}
					{hasAccess(UserAccess.ORDERS.HIDE_ORDERS) &&
						<ToolbarItem text={ticket?.hidden ? "Mostrar Boleto" : "Esconder Boleto"} icon={`eye ${ticket?.hidden ? null : 'slash'}`} onClick={showModal(MODAL.TICKET_HIDE)} />
					}
					<ToolbarItem text="Usuarios" icon="user" onClick={showModal(MODAL.TICKET_USER)} />
				</div>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={bindClose(setShownModal)} text="Cerrar" />
			</Modal.Actions>
		</Modal>
		<Modal open={!!ticket && shownModal===MODAL.TICKET_DETAILS} onClose={bindClose(setShownModal)} size="tiny">
			<Modal.Header>Boleto {ticket?.ticket_hash}</Modal.Header>
			{ticket && <Modal.Content>
				<table className="fr details table">
					<tbody>
						{[
							['Identificador', ticket.ticket_hash],
							['Estatus', ticket.paid ? 'Pagado' : (ticket.cancelled ? 'Cancelado' : (order.expired ? 'Expirado' : 'Pendiente'))],
							['Event', `[${ticket.event_id}] ${ticket.event_name}`],
							['Fecha', `[${ticket.date_id}] ${moment.unix(ticket.ticket_date || ticket.date).format('DD/MMM/YY HH:mm:ss')}`],
							['Producto', `[${ticket.price_id}] ${ticket.price_name}`],
							['Section', `[${ticket.section_id}] ${ticket.section_name}`],
							['IDButaca', ticket.seat_id],
							['Butaca Sección', ticket.seat_section],
							['Butaca Fila', ticket.seat_row],
							['Butaca Número', ticket.seat_number],
							['Costo', addCommas(ticket.ticket_cost)],
							['Comisión', addCommas(ticket.commission)],
							['Costo Final', `${addCommas(ticket.ticket_cost+ticket.commission)}`],
						].map((a, i)=>(
							<tr key={`tkdtl-${i}`}>
								<td>{a[0]}</td>
								<td>{a[1]}</td>
							</tr>
						))}
					</tbody>
				</table>
			</Modal.Content>}
			<Modal.Actions>
				<Button text="Cerrar" onClick={bindClose(setShownModal)} />
			</Modal.Actions>
		</Modal>
		<Modal open={!!ticket && shownModal===MODAL.TICKET_FORM} onClose={bindClose(setShownModal)} size="tiny">
			<Modal.Header>Formulario Boleto {ticket?.ticket_hash}</Modal.Header>
			{ticket && <Modal.Content>
				{ticketForm ? (
					<TicketForm form={ticketForm} onChange={setTicketForm} />
				) : (
					<Header loading text="Cargando formulario" />
				)}
				<Message list={modalPrompts} type="error" />
			</Modal.Content>}
			<Modal.Actions>
				<Button text="Cerrar" onClick={bindClose(setShownModal)} />
				<Button text="Guardar" color="black" onClick={sendFormEdit} />
			</Modal.Actions>
		</Modal>
		<Modal open={!!ticket && shownModal===MODAL.TICKET_EDIT} onClose={bindClose(setShownModal)} size="mini">
			<Modal.Header>Editar Boleto {ticket?.ticket_hash}</Modal.Header>
			{ticket && <Modal.Content>
				<Input label="Precio" value={ticket.ticket_cost} onChange={onTicketEditChange('ticket_cost')} />
				<Input label="Comissión" value={ticket.commission} onChange={onTicketEditChange('commission')} />
				<Stat label="Precio final boleto" value={addCommas((parseFloat(ticket.ticket_cost.toString()) || 0)+(parseFloat(ticket.commission.toString()) || 0))} style={{ paddingBottom: 10}} />
				<Message list={modalPrompts} type="error" style={{ marginTop: 15 }} />
			</Modal.Content>}
			<Modal.Actions>
				<Button text="Cerrar" onClick={bindClose(setShownModal)} />
				<Button text="Guardar" color="black" onClick={sendTicketEdit} />
			</Modal.Actions>
		</Modal>
		<Modal open={!!ticket && shownModal===MODAL.TICKET_USES} onClose={bindClose(setShownModal)} size="tiny">
			<Modal.Header>Usos Boleto {ticket?.ticket_hash}</Modal.Header>
			{ticket && <Modal.Content>
				{ticketUses ? (
					ticketUses.length>0 ? (
						<table className="fr fitted table" style={{ marginTop: -25 }}>
							<thead>
								<tr>
									<th className="collapsing">ID</th>
									<th>Mensaje</th>
									<th>Usuario</th>
									<th>Fecha</th>
									{hasAccess([20004, 20003], true) && (
										<th style={{ textAlign: 'center' }} className="collapsing"><Icon name="remove" /></th>
									)}
								</tr>
							</thead>
							<tbody>
								{ticketUses.map(a=>(
									<tr key={`tkuse-${a.use_id}`}>
										<td>{a.use_id}</td>
										<td>
											{a.message ? a.message : (
												<i style={{ color: 'gray' }}>Sin mensaje</i>
											)}
										</td>
										<td className="collapsing">
											[{a.admin_id}] {a.user_username.toUpperCase()}
										</td>
										<td className="collapsing">{moment.unix(a.date_created).format('DD/MM/YY HH:mm')}</td>
										{hasAccess([20004, 20003], true) && (
											<td>
												<Button icon iconName="remove" className="tiny" onClick={()=>setTicketUseSelected(a)} />
											</td>
										)}
									</tr>
								))}
							</tbody>
						</table>
					) : (
						<Header text="Este boleto no tiene usos" iconName="smile" size="small" />	
					)
				) : (
					<Header loading text="Cargando usos del boleto" />
				)}
			</Modal.Content>}
			<Modal.Actions>
				<Button text="Cerrar" onClick={bindClose(setShownModal)} />
			</Modal.Actions>
		</Modal>
		<Modal open={!!ticket && shownModal===MODAL.TICKET_NEW_USE} onClose={bindClose(setShownModal)} size="mini">
			<Modal.Header>Usar boleto {ticket?.ticket_hash}</Modal.Header>
			<Modal.Content>
				<Header size="small" text="Usar boleto" subtext="Esto agregará un uso a el boleto." style={{ marginTop: 0 }} />
				<Input value={ticketUseDate} label="Fecha de uso" calendar={{
					date: ticketUseDate
				}} />
				<Message type="error" list={modalPrompts} style={{ marginTop: 15 }} />
			</Modal.Content>
			<Modal.Actions>
				<Button text="Cerrar" basic onClick={bindClose(setShownModal)} />
				<Button text="Usar boleto" color="black" onClick={sendUseTicket} />
			</Modal.Actions>
		</Modal>
		<Modal open={!!ticketUseSelected} onClose={bindClose(setTicketUseSelected, null)} size="mini">
			<Modal.Header>Eliminar uso boleto {ticket?.ticket_hash}</Modal.Header>
			{!!ticketUseSelected && <Modal.Content>
				<Header text={`Eliminar uso ${ticketUseSelected.use_id}`} subtext="Esto eliminará este uso en especifico del boleto." />
				<Message type="error" list={modalPrompts} style={{ marginTop: 15 }} />
			</Modal.Content>}
			<Modal.Actions>
				<Button text="Cerrar" basic onClick={bindClose(setTicketUseSelected, null)} />
				<Button text="Eliminar uso" color="red" onClick={deleteTicketUse} />
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal===MODAL.CONCILIA} onClose={bindClose(setShownModal)} size="tiny" className="order-payment-concilia-manual">
			<Modal.Header>Conciliar orden</Modal.Header>
			{shownModal===MODAL.CONCILIA && <Modal.Content>
				<Header size="small" text="Conciliar orden" subtext="Conciliar los depositos o reembolsos de dinero de una orden." style={{ marginTop: 0, marginBottom: 15 }} />
				<Field label="Tipo de deposito" style={{ marginTop: 10 }}>
					<Dropdown selection onChange={onDepositFormChange('type', true)} placeholder="Tipo de deposito" options={[
						{ value: OrderDepositType.DEPOSIT, text: 'Deposito' },
						{ value: OrderDepositType.REFUND, text: 'Reembolso' },
						{ value: OrderDepositType.CHARGEBACK, text: 'Contracargo' },
					]} />
				</Field>
				{depositForm.type && <>
					<Groupper title="Forma de pago" style={{ marginTop: 15, overflow: 'hidden' }}>
						<Items
							selectable
							toggle
							single
							data={active_payments}
							style={{ marginTop: -20 }}
							valueExtractor={a=>a.payment_id}
							selected={depositForm?.payment_id}
							onToggle={onDepositFormChange('payment_id')}
							render={a=>{
								var deposits = order.concilia.filter(b=>b.payment_id==a.payment_id);
								var payment_total = depositForm.type===OrderDepositType.DEPOSIT ? a.total : a.total_refunded;
								var deposit_charge = 0, deposit_refund = 0;
								
								for(var i of deposits){
									if(i.deposit_type===OrderDepositType.DEPOSIT){
										deposit_charge += i.amount;
									}else{
										deposit_refund += i.amount;
									}
								}
								var deposit_amount = depositForm.type===OrderDepositType.DEPOSIT ? deposit_charge : deposit_refund;

								return <>
									<div className="left">
										<div className="meta small">ID: {a.payment_id}</div>
										<div className="name">{a.method_name_internal}</div>
										{deposit_amount>0 && (
											<div className="meta">Depositado: {addCommas(deposit_amount)}</div>
										)}
									</div>
									<div className="right">
										<div className="total name">{addCommas(payment_total-Math.abs(deposit_amount))}</div>
										{deposit_amount>0 && (
											<div className="meta">Total: {addCommas(payment_total)}</div>
										)}
									</div>
								</>
							}}
						/>
					</Groupper>
					<Message type="info" centered style={{ marginTop: 15 }}>
						{(!!depositForm?.type && (depositForm?.type===OrderDepositType.DEPOSIT ? total_concilia_deposits>=total_paid : (Math.abs(total_concilia_refunds)>=Math.abs(total_refunded)))) && (
							<div style={{ paddingBottom: 10 }}>Esta orden ya había conciliada por completo</div>
						)}
						<Stat.Group>
							<Stat 
								className="small" 
								label={'Conciliado'} 
								style={{ padding: 0 }} 
								value={<>
									{addCommas(total_concilia_deposits+(parseFloat(depositForm.amount) || 0))}
									<span style={{ fontWeight: 'normal', fontSize: 20, lineHeight: 0 }}>/{addCommas(OrderDepositType.DEPOSIT ? total_paid : Math.abs(total_refunded))}</span>
								</>} 
							/>
						</Stat.Group>
					</Message>
					{!!depositForm.payment_id && <>
						<Field amount={2} style={{ marginTop: 15 }}>
							<Input label="Cantidad" value={depositForm.amount} onChange={onDepositFormChange('amount')} />
							<Input label="Fecha de deposito" value={depositForm.date_deposit} onChange={onDepositFormChange('date_deposit')} calendar={{
								date: depositForm.date_deposit,
								mode: 'date'
							}} />
						</Field>
						<Input label="Referencia" placeholder="(Opcional) Numero de control o referencia de la forma de pago" value={depositForm?.external_id} onChange={onDepositFormChange('external_id')} />
					</>}
				</>}
				<Message type="error" list={modalPrompts} />
			</Modal.Content>}
			<Modal.Actions>
				<Button text="Cerrar" basic onClick={bindClose(setShownModal)} />
				<Button text="Concilia" color="black" disabled={!depositForm?.amount || parseFloat(depositForm?.amount)<=0} onClick={createDeposit} />
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal===MODAL.ORDER_REFUND} onClose={bindClose(setShownModal)} size="tiny" className="refund-create">
			<Modal.Header>Reembolsar boletos</Modal.Header>
			{shownModal===MODAL.ORDER_REFUND && <Modal.Content>
				{pending_refunds.length>0 && (
					<Message size="small" type="info" header="Reembolso pendiente" text="Ya existen reembolsos no completados de esta orden. Si se realiza otro reembolso se cancelarán los reembolsos no completados actuales." style={{ marginBottom: 10, marginTop: -5 }} />
				)}
				<Items
					selectable
					data={order.tickets.filter(a=>a.paid && !a.cancelled)}
					valueExtractor={a=>a.ticket_id}
					selected={ticketsSelected}
					onToggle={setTicketsSelected}
					toggle
					render={(a)=><TicketItem ticket={a} price />} 
				/>
				<Input 
					label="Correo electrónico" 
					comment={order.email && order.email.length>=5 ? 'Ya que la orden ya tiene un correo electrónico asignado no se puede enviar a otra dirección de correo.' : null} 
					value={order.email || refundForm.email} 
					readonly={!!order.email && order.email.length>5} 
					onChange={onRefundFormChange('email')} 
					style={{ marginTop: 5 }} 
				/>
				<div style={{ height: 5 }} />
				{user_access.refund_complete && refund_commission_amount>0 && (
					<Checkbox toggle label={`Reembolsar $${addCommas(refund_commission_amount)} de comisión`} style={{ marginTop: 5 }} checked={!!refundForm.commission} onChange={onRefundFormChange('commission', true)} />
				)}
				{user_access.refund_complete && order.payment_cost>0 && (
					<Checkbox toggle label={`Reembolsar $${addCommas(order.payment_cost)} de forma de pago`} style={{ marginTop: 5 }} checked={!!refundForm.payment} onChange={onRefundFormChange('payment', true)} />
				)}
				{user_access.refund_complete && order.delivery_cost>0 && (
					<Checkbox toggle label={`Reembolsar $${addCommas(order.delivery_cost)} de entrega`} style={{ marginTop: 5 }} checked={!!refundForm.delivery} onChange={onRefundFormChange('delivery', true)} />
				)}
				{user_access.refund_fake && refund_total>0 && (
					<Checkbox toggle label={'Solo marcar como reembolsada'} style={{ marginTop: 5 }} checked={!!refundForm.fake} onChange={onRefundFormChange('fake', true)} />
				)}
				{user_access.refund_fake && refundForm.fake && (
					<Checkbox toggle label={'Enviar correo a cliente'} style={{ marginTop: 5 }} checked={!!refundForm.send_email} onChange={onRefundFormChange('send_email', true)} />
				)}
				{refund_total>0 && (
					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Table
							className="basic totals"
							nullIsDivider={false}
							data={[
								['Boletos', addCommas(refund_tickets_amount)],
								(refundForm && refundForm.commission) ? (
									['Comisión', addCommas(refund_commission_amount)]
								) : null,
								(refundForm && refundForm.payment) ? (
									['Comisión', addCommas(order.payment_cost)]
								) : null,
								(refundForm && refundForm.delivery) ? (
									['Comisión', addCommas(order.delivery_cost)]
								) : null,
								['Reembolso', addCommas(refund_total)]
							]}
						/>
					</div>
				)}
				{refund_total>0 && (
					<Message type="info" style={{ marginTop: 10 }}>
						{user_access.refund_fake && refundForm.fake ? (
							'Se creará un reembolso con la configuración seleccionada y se marcará como completado.' + (
								refundForm.send_email ? ' Se le enviará un correo a cliente de que su orden fue reembolsada.' : ''
							)
						) : (
							'Se le enviará un correo electrónico a el cliente para que pueda completar el reembolso. Si es necesario, se le pedirá a el cliente su información bancaria para realizar una transferencia bancaria.'
						)}
					</Message>
				)}
				<Message type="error" list={modalPrompts} />
			</Modal.Content>}
			<Modal.Actions>
				<Button text="Cerrar" basic onClick={bindClose(setShownModal)} />
				<Button text="Reembolsar" color="red" disabled={refund_total<=0} onClick={createRefund} />
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal===MODAL.DELETE_CONCILIA && !!selected_concilia} onClose={bindClose(setShownModal)} size="mini">
			<Modal.Header>Eliminar conciliación</Modal.Header>
			{shownModal===MODAL.DELETE_CONCILIA && selected_concilia && <Modal.Content>
				<Header size="small" text="¿Eliminar conciliación?" subtext="Esto eliminará la concilicación seleccionada." style={{ marginTop: 0 }} />
				<Stat label="Conciliación eliminada" value={addCommas(selected_concilia.amount)} style={{ paddingBottom: 10 }} className="red" />
			</Modal.Content>}
			<Modal.Actions>
				<Button text="Cerrar" basic onClick={bindClose(setShownModal)} />
				<Button text="Eliminar" color="red" onClick={deleteDeposit} />
			</Modal.Actions>
		</Modal>
		<Modal open={!!ticket && shownModal===MODAL.TICKET_USER} onClose={bindClose(setShownModal)} size="mini">
			<Modal.Header>Usuarios</Modal.Header>
			<Modal.Content>
				<Table
					details
					striped
					style={{ margin: 'auto', maxWidth: 600 }}
					data={[
						['Creador', <>
							{ticket?.admin_creator_name?.toUpperCase() || (
								<i>Sin creador</i>
							)}
							{ticket?.admin_creator ? (
								<div className="meta">ID: {ticket?.admin_creator}</div>
							) : null}
						</>],
						['Fecha creación', <>
							{moment.unix(ticket?.date_created).format('DD/MMM/YY HH:mm')}
						</>],
						...(ticket?.cancelled ? [
							null,
							['Cancelador', <>
								{ticket.admin_cancelled_name}
								<div className="meta">ID: {ticket.admin_cancelled}</div>
							</>],
							['Fecha cancelación', <>
								{moment.unix(ticket.date_cancelled).format('DD/MMM/YY HH:mm')}
							</>],
						] : []),
					]}
				/>
			</Modal.Content>
			<Modal.Actions>
				<Button text="Cerrar" onClick={bindClose(setShownModal)} />
			</Modal.Actions>
		</Modal>

		{ !!ticket &&
			<Modal open={shownModal===MODAL.TICKET_HIDE} onClose={bindClose(setShownModal)} size="tiny">
				<Modal.Header>{ticket.hidden ? 'Mostrar Boleto' : 'Esconder Boleto'}</Modal.Header>
				<Modal.Content>
					<Header text={ticket.hidden ? `Mostrar boleto` : `Esconder boleto`} subtext={`Esto ${ticket.hidden ? 'mostrara' : 'escondera'} el boleto ${ticket?.ticket_hash}`} />
					<Table
						details
						title="Boleto"
						titleSize="small"
						style={{ marginBottom: 15 }}
						data={[
							['Boleto', ticket.ticket_hash],
							['Evento', ticket.event_name],
							['Fecha', moment.unix(ticket.ticket_date || ticket.date).format('DD/MMM/YY HH:mm')],
							null,
							['Sección', ticket.section_name],
							['Producto', ticket.price_name],
							...(ticket.seat_id ? [['Butaca', `${ticket.seat_section || ''} ${formatSeatNumber(ticket.seat_row, ticket.seat_number)}`]] : []),
							['Precio', addCommas(ticket.ticket_cost+ticket.commission)],
						]}
					/>
					<Message>
						Los boletos escondidos no saldrán en reportes dentro del sistema.
						Estos serán contabilizados solo dentro de las estadisticas del administrador de AREMA, y solo se mostrarán a administradores con acceso para ver estos boletos.
					</Message>
				</Modal.Content>
				<Modal.Actions>
					<Button text="Cerrar" basic onClick={bindClose(setShownModal)} />
					<Button text={ticket.hidden ? "Mostrar" : "Esconder"} color="red" onClick={onHideTicket} />
				</Modal.Actions>
			</Modal>
		}
		
		{ticket &&
			<UpdateSeatTicketModal 
				open={!!ticket && shownModal===MODAL.TICKET_UPDATE_SEAT} 
				onClose={bindClose(setShownModal)}
				date_id={ticket.date_id} 
				section_id={ticket.section_id} 
				title="Selecciona un Asiento"
				onSeatSelected={onTicketChangeSeat}
				onSave={onSaveNewTicketSeat}
				specialSeats={[ticket.seat_id]}
				selectedSeats={newTicketSeat ? [newTicketSeat.seat_id] : []}
				ticket_id={ticket.ticket_id}  
			/>
		}

		{!!paymentIdSelected &&
			<PaymentInfoModal 
				editable={true}
				open={shownModal===MODAL.PAYMENT_INFO} 
				onPaymentEdit={onPaymentChange}
				onClose={bindClose(setShownModal)}
				payment_id={paymentIdSelected}
			/>
		}

		{ order &&
			<OrderInvoicesModal 
				order_id={order.order_id} 
				order_hash={order.order_hash}
				open={shownModal===MODAL.ORDER_INVOICES} 
				onClose={bindClose(setShownModal)} 
			/>
		}

		{
			order &&
			<LocationModal 
				location_id={order.delivery_location} 
				open={shownModal===MODAL.ORDER_LOCATION} 
				onClose={bindClose(setShownModal)} 
				onLocationEdit={onLocationChange}
				editable={true}
			/>
		}

		{ order &&
			<OrderEmailsModal 
				order_id={order.order_id} 
				order_hash={order.order_hash}
				open={shownModal===MODAL.ORDER_EMAILS} 
				onClose={bindClose(setShownModal)} 
			/>
		}
	</div>
}

export default OrderView;